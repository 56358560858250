.product-feedback-cont{
    width: 45%;
    margin: auto;
}
.product-body .swiper-slide {
    width: 512px !important;
    aspect-ratio: 3/2;
    border-radius: 6px;
    overflow: hidden;
    background-color: #f5f5f5;
    animation: loadingProductImgs 1.5s infinite;
}

.product-break{
    display: flex;
    gap: 50px;
}

.product-swiper{
    max-width: 550px;
    height: 100%;
}


@keyframes loadingProductImgs {
    0% {
        background-color: #f5f5f579;

    }
    50% {
        background-color: #f5f5f5;

    }
    100% {
        background-color: #f5f5f579;

    }
  }
@media screen and (max-width:768px) {
    .product-feedback-cont{
        width: 75%;
        margin: auto;
    }
    .product-body .swiper-slide {
        width: 100%;
        max-width: 360px;
        border-radius: 6px;
        overflow: hidden;
    }
}