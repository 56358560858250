.main-heading {
    width: 100%;
}

.main-heading .text-wrapper {
    color: var(--jet-black);
    font-family: freight-big-pro, Helvetica;
    font-size: 36px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 76.8px;
    position: relative;
    text-align: center;
    top: 0;
}

.basic-heading {
    width: fit-content;
}

.basic-heading .text-wrapper {
    color: var(--jet-black);
    font-family: freight-big-pro, Helvetica;
    font-size: 36px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 76.8px;
    position: relative;
    text-align: center;
    top: 0;
}

.simple-heading {
    width: 100%;
}

.simple-heading .text-wrapper {
   


    color: var(--Jet-Black, #333332);

font-feature-settings: 'clig' off, 'liga' off;
font-family: "HK Grotesk-Medium", Helvetica;
font-size: 36px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.heding-subheding {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    width: 100%;
}

.heding-subheding .headding {
    color: var(--newjet-black);
    font-family: 'freight-big-pro', Helvetica;
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: fit-content;
}

.heding-subheding .sub-heading {
    color: var(--jet-black-1);
    font-family: "HK Grotesk-Regular", Helvetica;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 140%;
    position: relative;
    text-align: center;
    width: 80%;
}

.heding-subheding-simple {
    align-items: start;
    display: flex;
    flex-direction: column;
    gap: 1px;
    position: relative;
    width: 100%;
}

.heding-subheding-simple .headding {
    color: var(--newjet-black);
    font-family: freight-big-pro, Helvetica;
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: fit-content;
}

.heding-subheding-simple .sub-heading {
    color: #33333299;
    font-family: "HK Grotesk-RegularLegacy", Helvetica;
    font-size: 28px;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: left;
    width: 80%;
}

.heding-subheding-button {
    align-items: start;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    width: 100%;
    max-width: 560px;
}

.heding-subheding-button .headding {
    color: var(--newjet-black);
    font-family: freight-big-pro, Helvetica;
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: left;
    width: fit-content;
}

.heding-subheding-button .sub-heading_2 {
    color: #33333299;
    font-family: "HK Grotesk-RegularLegacy", Helvetica;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 140%;
    position: relative;
    text-align: left;
    width: 80%;
}

.heding-subheding-button .sub-heading {
    color: #33333299;
    font-family: "HK Grotesk-RegularLegacy", Helvetica;
    font-size: 22px;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: left;
    width: 80%;
}

.IconDiscriptin{
    display: flex;
    flex-direction: column;
    gap: 18px;
}
.IconDiscriptin .cont{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: inherit;
    justify-content: space-between;
}
.IconDiscriptin .text-wraper{
    display: flex;
    gap: 14px;
    align-items: center;
    width: 28%;
}
.IconDiscriptin .text-wraper .icon{
    height: 32px;
    width: 32px;
}

.IconDiscriptin .text-wraper .text{
    font-family: "HK Grotesk-Medium", Helvetica;
    font-size: 18px;
}

@media screen and (max-width:768px) {
    .heding-subheding-button {
        align-items: start;
        display: flex;
        flex-direction: column;
        gap: 7px;
        position: relative;
        width: 100%;
        max-width: 560px;
    }
    
    .heding-subheding-button .headding {
        color: var(--newjet-black);
        font-family: freight-big-pro, Helvetica;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 140%;
        position: relative;
        text-align: left;
        width: fit-content;
    }
    
    .heding-subheding-button .sub-heading_2 {
        color: #33333299;
        font-family: "HK Grotesk-RegularLegacy", Helvetica;
        font-size: 11px;
        letter-spacing: 0;
        line-height: 140%;
        position: relative;
        text-align: left;
        width: 80%;
    }
    
    .heding-subheding-button .sub-heading {
        color: #33333299;
        font-family: "HK Grotesk-RegularLegacy", Helvetica;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 140%;
        position: relative;
        text-align: left;
        width: 80%;
    }
    .heding-subheding-button{
        display: none;
    }
    .main-heading .text-wrapper {
        color: var(--new-jet-black, #333332);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .basic-heading .text-wrapper {
        color: var(--new-jet-black, #333332);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .main-heading{
        width: 75%;
        margin-left: 12.5%;
    }
    .heding-subheding .headding{
        font-size: 20px;
        line-height: normal;
    }
    .heding-subheding .sub-heading{
        font-size: 11px;
    }
    .heding-subheding-simple .headding{
        font-size: 20px;
        line-height: 21px;
    }
    .heding-subheding-simple .sub-heading{
        font-size: 12px;
        line-height: 18px;
    }
    .simple-heading .text-wrapper {
        font-size: 14px;
    }
    .IconDiscriptin{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .IconDiscriptin .cont{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: inherit;
    }
    .IconDiscriptin .text-wraper{
        display: flex;
        gap: 8px;
        min-width: 100%;
    }
    .IconDiscriptin .text-wraper .icon{
        height: 20px;
        width: 20px;
    }
    
    .IconDiscriptin .text-wraper .text{
        font-family: "HK Grotesk-Medium", Helvetica;
        font-size: 11px;
    }
}