  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    position: absolute;
  }
  
  ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0); 
      border-radius: 10px;
      position: absolute;
  }
  
  ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba(56, 55, 54, 0.279);
  }

:root {
    /* --isabeline: rgba(252, 252, 252, 1); */
    --isabeline: rgb(255, 255, 255);

    --jet-black: #333332;
    --jet-black-2: rgba(89, 89, 88, 1);
    --jet-black-3: rgba(127, 127, 126, 1);
    --jet-black-4: rgba(124, 124, 124, 1);
    --jet-black-5: rgba(202, 202, 202, 1);
    --jet-black-6: rgba(221, 221, 221, 1);
    --jet-black-7: rgba(231, 231, 231, 1);
    --jet-black-8: rgba(239, 239, 239, 1);

    --br-1:8px;
    --br-max:80px;

    --newisabeline: rgba(247, 245, 242, 1);
    --newjet-black: rgba(51, 51, 50, 1);
    --newpersian-red: rgba(183, 62, 51, 1);
    --persian-red: rgba(183, 62, 51, 1);

    --font-xxl: 64px;
    --font-xl: 36px;
    --font-l: 32px;
    --font-m: 28px;
    --font-s: 20px;

}

.body{
    gap: 60px;
    display: flex;
    flex-direction: column;
    padding-bottom: 100px;
    margin-top: 60px;
}

.loco-body{
    padding-top:40px;
    /* border-radius:80px 80px 0px 0; */
    margin-top: 6600px;
}

.dashboard-body .body{
    margin-top: 0;
    padding-bottom: 0;
}

.hide-desktop-f{
    display: none !important;
}

@media screen and (max-width: 768px) {
    :root {
        --font-xxl: 32px;
        --font-xl: 18px;
        --font-l: 16px;
        --font-m: 14px;
        --font-s: 10px;

    --br-1:4px;

    }
    .body{
        gap: 40px;
        margin-top: 100px;
    }
    .loco-body{
        padding-top:20px;
        /* border-radius:40px 40px 0px 0px; */
        margin-top: 4600px;
    }
    ::-webkit-scrollbar {
        width: 0px;
        height: 5px;
        position: absolute;
      }
      .hide-desktop-f{
        display: flex !important;
    }
    
}

body,html{
    background-color: var(--isabeline);
    overflow-x: hidden;
}