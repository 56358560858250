.dashboard-body .tag1 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 2px;
    position: relative;
    width: 100%;
}
.op
{
    gap:'400px' !important;
}
.kami
{
    color: var(--isabeline);
    font-family: 'Raleway', serif;
    font-size: 42px;
    font-weight: 300;
    letter-spacing: -3px;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    align-items: center;
}
.dashboard-body .tag1 .text-wrapper {
    color: var(--jet-black);
    font-family: "HK Grotesk-Regular", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.dashboard-body .tag1 .div {
    color: var(--jet-black);
    font-family: "HK Grotesk-Light", Helvetica;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: normal;
    opacity: 0.4;
    position: relative;
    width: fit-content;
}

.dashboard-body .tag-credit {
    display: flex;
    width: fit-content;
    flex-direction: column;
    justify-content: flex-start;
}

.dashboard-body .tag-credit .sub-heading {
    color: var(--jet-black);
    font-family: "HK Grotesk-LightLegacy", Helvetica;
    font-size: 22px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: normal;
    opacity: 0.4;
}

.dashboard-body .tag-credit .heading {
    color: var(--jet-black);
    font-family: "HK Grotesk-BoldLegacy", Helvetica;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: left;
}


@media screen and (max-width:768px) {
    .dashboard-body .tag1 .text-wrapper{
        font-size: 16px;
    }
    .dashboard-body .tag1 .div{
        font-size: 12px;
        
    }
    .dashboard-body .tag-credit .sub-heading {
        font-size: 16px;
    }
    .dashboard-body .tag-credit .heading {
        font-size: 18px;
    }
    .op
    {
        gap:'100px' !important;
    }
}