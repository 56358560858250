/* Add this CSS to your stylesheets or inline styles in your React component */
.popup-cont {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999999999999;
  }
  

  .popup {
    align-items: center;
    background-color: var(--isabeline);
    display: inline-flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px;
    position: relative;
    max-width: 80%;
    border-radius: var(--br-1);
}

.popup .heading {
    color: var(--jet-black);
    font-family: "HK Grotesk-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: fit-content;
}

.popup .text {
    align-self: stretch;
    color: var(--jet-black);
    font-family: "HK Grotesk-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    position: relative;
    text-align: center;
}

.popup .popup-frame {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
}

.popup .button-mobile {
    align-items: center;
    border: 1px solid;
    border-color: #333332;
    display: flex;
    gap: 20px;
    justify-content: center;
    padding: 12px 20px;
    position: relative;
    width: 79px;
    border-radius: var(--br-max);

}

.popup .text-wrapper {
    color: var(--jet-black);
    font-family: "HK Grotesk-Medium", Helvetica;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.popup .accept-wrapper {
    align-items: center;
    background-color: var(--jet-black);
    border: 1px solid;
    border-color: var(--jet-black);
    display: inline-flex;
    flex: 0 0 auto;
    gap: 20px;
    justify-content: center;
    padding: 12px 20px;
    position: relative;
    border-radius: var(--br-max);

}

.popup .accept {
    color: var(--isabeline);
    font-family: "HK Grotesk-Medium", Helvetica;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    border-radius: var(--br-max);

}

.pointer{
  cursor: pointer;
}

.popup-bottom-cont {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999999999999;
  }
  

  .popup-bottom {
    align-items: center;
    background-color: var(--isabeline);
    display: inline-flex;
    flex-direction: column;
    gap: 30px;
    padding: 40px 30px;
    padding-bottom: 40px;
    position: relative;
    width: 100%;
    margin-top: auto;
    border-radius: 25px 25px 0px 0px;
}

.popup-bottom .heading {
    color: var(--jet-black);
    font-family: "HK Grotesk-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: fit-content;
}

.track-text-cont{
    position: relative;
    width: 100%;
    gap: 20px;
    display: flex;
    align-items: center;
}
.track-text-cont .icon{
    height: 24px;
    width: 24px;
    padding: 2px;
    background-color: var(--isabeline);
}
.track-text-cont .connector{
    position: absolute;
    height: 100%;
    width: 0px;
    top:calc(50% + 14px);
    left: 12px;
    border-left: 1px dashed black;
}
.track-text-cont .text-wraper{
    display: flex;
    flex-direction: column;
}
.track-text-cont .text-wraper .heading{
    font-size: 13px;
    font-family: "HK Grotesk-SemiBold";
    margin-bottom: 0px;
    margin-bottom: 5px;
}
.track-text-cont .sub-heading{
    font-size: 10px;
    font-family: "HK Grotesk-Medium";
}
@media screen and (min-width:769px) {
    .popup-bottom {
        align-items: center;
        background-color: var(--isabeline);
        display: inline-flex;
        flex-direction: column;
        gap: 30px;
        padding: 40px 30px;
        padding-bottom: 60px;
        position: relative;
        width: fit-content;
        margin-top: auto;
        margin-bottom: auto;
        border-radius: var(--br-1);
    }
}