.pricing-card-new-d2c {
    align-items: flex-start;
    border: 0.5px solid var(--jet-black-2);
    display: inline-flex;
    flex-direction: column;
    gap: 40px;
    padding: 30px;
    position: relative;
    color: var(--jet-black);
    max-width: 430px;
    border-radius: var(--br-1);
  }
  
  .pricing-card-new-d2c .datails {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
    width: 100%;
  }
  
  .pricing-card-new-d2c .name-cont {
    align-items: center;
    align-self: stretch;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: #3333324d;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    padding: 0px 0px 16px;
    position: relative;
    width: 100%;
  }
  
  .pricing-card-new-d2c .name {
    color: inherit;
    font-family: freight-big-pro, Helvetica;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  
  .pricing-card-new-d2c .svg-text {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 6px;
    justify-content: flex-end;
    align-items: center;
    position: relative;
  }
  
  .pricing-card-new-d2c .svg {
    height: 24px;
    position: relative;
    width: 24px;
  }
  
  .pricing-card-new-d2c .svg-text .text {
    color: inherit;
    font-family: "HK Grotesk-RegularLegacy", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1px;
    opacity: 0.6;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .pricing-card-new-d2c .bullets {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    gap: 8px 11px;
    position: relative;
    width: 100%;
  }
  
  .pricing-card-new-d2c .pricing {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 2px;
    position: relative;
    width: 100%;
  }
  
  .pricing-card-new-d2c .price {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 12px;
    justify-content: flex-end;
    position: relative;
  }
  
  .pricing-card-new-d2c .value {
    color: inherit;
    font-family: "HK Grotesk-BoldLegacy", Helvetica;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .pricing-card-new-d2c .actual-value {
    color: inherit;
    font-family: "HK Grotesk-Medium", Helvetica;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    opacity: 0.4;
    position: relative;
    text-align: center;
    text-decoration: line-through;
    width: fit-content;
  }
  
  .pricing-card-new-d2c .heading {
    color: inherit;
    font-family: "HK Grotesk-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .pricing-card-new-d2c .sub-heading {
    color: inherit;
    opacity: 0.6;
    font-family: "HK Grotesk-RegularLegacy", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
/*Catigory card*/


.category-card-stroke {
  align-items: flex-start;
  border:0.5px solid var(--jet-black-2);
  flex-direction: column;
  gap: 4px;
  padding: 14px 28px;
  position: relative;
  gap: 16px;
  padding: 25px 50px;
  display: flex;
  width: 381px;
  flex-shrink: 0;
  border-radius: var(--br-1);
  cursor: pointer;
}

.category-card-stroke .mag-svg {
 height: 60px;
 width: 60px;
 position: relative;
}

.category-card-stroke .mag-name {
  align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    text-align: center;
    flex-direction: column;
    position: relative;
}

.category-card-stroke .text-wrapper {
  color: var(--jet-black);
    font-family: "HK Grotesk-Medium", Helvetica;
    font-size: 16px;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  font-size: 28px;
}

.category-card-stroke .div {
  color: var(--jet-black);
    font-family: "HK Grotesk-RegularLegacy", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    width: fit-content;
  font-size: 16px;
}

/*Super catigory*/

.super-category {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 18px;
  position: relative;
  align-items: center;
  margin: auto;
  width: fit-content;
  border-radius: var(--br-1);
}

.super-category .icon-category-name {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 0.25px;
  border-color: #333332;
  display: flex;
  flex: 0 0 auto;
  gap: 18px;
  padding: 0px 0px 10px;
  position: relative;
  width: 100%;
}

.super-category .svg {
  height: 58px;
  position: relative;
  width: 58px;
}

.super-category .text-wrapper {
  color: var(--jet-black);
  font-family: "HK Grotesk-Medium", Helvetica;
  font-size: 38px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.super-category .div {
  color: #33333299;
  font-family: "HK Grotesk-RegularLegacy", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 140%;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

/*Sub-category*/

.sub-category-card {
  align-items: start;
  max-width: 330px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 28px 20px;
  position: relative;
  width: 647px;
  padding: 30px 40px;
  border: 0.5px solid var(--jet-black-2);
  justify-content: center;
  gap: 16px;
  cursor: pointer;
border-radius: var(--br-1);
  align-self: stretch;
}

.sub-category-card .celebration {
  height: 40px;
  position: relative;
  width: 40px;
  opacity: 0.8;
}

.sub-category-card .category-sub-text {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.sub-category-card .text-wrapper {
  align-self: stretch;
  color: var(--jet-black);
  font-family: "HK Grotesk-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  text-transform: none;
  opacity: 1;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.sub-category-card .div {
  align-self: stretch;
  color: var(--jet-black-2);
  font-family: "HK Grotesk-Medium", Helvetica;
  font-size: 16px;
  opacity: 1;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 140%;
  position: relative;
}



  @media screen and (max-width: 768px) {
    .pricing-card-new-d2c {
      gap:20px;
      padding: 20px 30px;
    }
    
    .pricing-card-new-d2c .datails {
      gap: 8px;
    }
    
    .pricing-card-new-d2c .name-cont {
      padding: 0px 0px 8px; 
    }
    
    .pricing-card-new-d2c .name {
      font-size: 18px;
    }
    
    .pricing-card-new-d2c .svg-text {
      gap: 4px;
    }
    
    .pricing-card-new-d2c .svg {
      height: 14px;
      width: 14px;
    }
    
    .pricing-card-new-d2c .svg-text .text {
      font-size: 9px;
    }
    
    .pricing-card-new-d2c .bullets {
      gap: 8px;
    }
    
    .pricing-card-new-d2c .pricing {
     gap: 2px; 
    }
    
    .pricing-card-new-d2c .price {
      gap: 8px;
    }
    
    .pricing-card-new-d2c .value {
      font-size: 24px;
    }
    
    .pricing-card-new-d2c .actual-value {
     font-size: 12px; 
    }
    
    .pricing-card-new-d2c .heading {
      font-size: 16px;
    }
    
    .pricing-card-new-d2c .sub-heading {
     font-size: 12px; 
    }

  /*Category card*/
  .category-card-stroke {
    

    display: inline-flex;
    flex-direction: column;
    gap: 8px;
    padding: 20px 28px;
    width: 140px;
    position: relative;
  }
  
  .category-card-stroke .mag-svg {
    height: 20px;
    position: relative;
    width: 20px;
  }
  
  .category-card-stroke .mag-name {
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
  }
  
  .category-card-stroke .text-wrapper {
    font-family: "HK Grotesk-Medium", Helvetica;
    font-size: 16px;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .category-card-stroke .div {
    font-family: "HK Grotesk-RegularLegacy", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    width: fit-content;
  }
  
  /*super catigory*/

  .super-category {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    width: 100%;
    position: relative;
  }
  
  .super-category .icon-category-name {
    align-items: center;
    justify-content: center;
    align-self: stretch;
    border-bottom-style: solid;
    border-bottom-width: 0.25px;
    border-color: #3333324d;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    padding: 0px 0px 5px;
    position: relative;
    width: 100%;
  }
  
  .super-category .svg {
    height: 35px;
    position: relative;
    width: 34px;
  }
  
  .super-category .text-wrapper {
    color: var(--jet-black);
    font-family: "HK Grotesk-Medium", Helvetica;
    font-size: 20px;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    width: fit-content;
  }
  
  .super-category .div {
    color: #33333299;
    font-family: "HK Grotesk-RegularLegacy", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 140%;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }

  /*Sub-category*/

.sub-category-card {
  align-items: center;
  margin: auto;
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
  min-width: 0;
  padding: 20px 16px;
  position: relative;
  padding: 16px 32px;
  align-self: stretch;
  border-radius: var(--br-1);
}

.sub-category-card .celebration {
  height: 22px;
  position: relative;
  width: 22px;
}

.sub-category-card .category-sub-text {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 5px;
  justify-content: center;
  position: relative;
}

.sub-category-card .text-wrapper {
  align-self: stretch;
  color: var(--jet-black);
  font-family: "HK Grotesk-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.24px;
  line-height: normal;
  margin-top: -1px;
  text-transform: capitalize;
  position: relative;
}

.sub-category-card .div {
  align-self: stretch;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 140%;
  position: relative;
}

  
    
  }
