.editions-marque {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 17.6px;
    position: relative;
    width: 237px;
    overflow-x: visible;
    margin-left: 250px;
}

.editions-marque .group {
    position: relative;
    width: 100%;
}


.editions-marque .group img {
   width: 100%;
   height: auto;
}


.editions-marque .frame {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 6.4px;
    margin-bottom: -6.21px;
    position: relative;
}

.editions-marque .birthday-edition {
    color: var(--jet-black);
    font-family: 'freight-big-pro', Helvetica;
    font-size: 24px;
    font-weight: 400;
    width: fit-content;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.8px;
    position: relative;
    text-align: center;
    white-space: nowrap;
}

.editions-marque .text-wrapper {
    color: #333332;
    font-family: 'freight-big-pro', Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    text-wrap: nowrap !important;
}

.editions-marque .span {
    font-size: 28px;
}

.editions-marque .div {
    color: var(--jet-black-3);
    font-family: "HK Grotesk-RegularLegacy", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 140%;
    position: relative;
    text-align: center;
    width: 100%;
    
}

@media screen and (max-width:768px) {
    .editions-marque {
        display: flex;
        width: 185px;
        gap: 8px;
        margin-left: 100px;
    }
    .editions-marque .birthday-edition {
        color: var(--jet-black-2);
        font-family: 'freight-big-pro', Helvetica;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: normal;
        margin-top: -0.8px;
        position: relative;
        text-align: center;
    }

    .editions-marque .text-wrapper {
        font-size: 16px;
        text-wrap: nowrap;
    }
    
    .editions-marque .span {
        font-size: 16px;
        
    }
    
    .editions-marque .div {
        font-size: 11px;
    }
}
