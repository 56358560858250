/* styles.css */
.video-background {
    position: relative;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 160/83;
    flex-shrink: 0;
  }
  .video-home{
    display: none;
  }
  
  @media screen and (max-width:768px) {
    .video-background{
        aspect-ratio: 5/7;
        display: none;
    }
    .video-home{
      display: block;
    }
  }
  .video-background video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: start;
    text-align: left;
    color: white;
    gap: 20px;
    padding-left: 20px;
  }
  
  .ordernowhome {
    display: inline-flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #FFF;
    color: var(--main-color);
    margin-bottom: 20px;
  }
  
  .content h1 {
    font-family: "DM Serif Display";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 111.6%;
    margin: 0;
    width: 70%;
  }
  
  .content2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 20px;
    align-items: center;
    text-align: left;
    color: white;
    gap: 20px;
    padding-left: 20px;
  }
  
  .content2 .heading {
    color: var(--isabeline);
    font-family: "HK Grotesk-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.25px;
    line-height: normal;
    margin-top: -1px;
    opacity: 0.8;
    position: relative;
    text-align: center;
    width: fit-content;
  }
  
  .content2 .text-cont {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    justify-content: center;
    position: relative;
  }
  
  .content2 .text {
    color: var(--isabeline);
    font-family: "FreightBigProMedium-Regular-☞", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.9px;
    position: relative;
    text-align: center;
    text-decoration: underline;
    width: fit-content;
  }
  
  .content2 .icon {
    height: 15.5px;
    position: relative;
    width: 15.5px;
  }
  