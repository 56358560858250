.contact{
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 18px;
    margin: auto;
    font-family: 'HK Grotesk-Regular';
}
.contact a{
    text-decoration: underline !important;
    color: var(--jet-black);
}
.contact img{
    height: 100%;
    width: auto;
}
.contact span{
    white-space: nowrap;
}
.contact .svg-img{
    max-height: 40px;
}
@media screen and (max-width: 768px) {
    .contact {
        gap: 7px;
        font-size: 12px;
    }
    .contact img{
        max-height: 21px;
    }
    .contact .svg-img{
        max-height: 21px;
        min-width: 21px;
    }
}