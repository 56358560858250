.order-card {
    align-items: flex-start;
    display: inline-flex;
    gap: 24px;
    position: relative;
    width: fit-content;
}

.order-card .poster {
    background-color: var(--jet-black);
    position: relative;
    width: 240px;
    aspect-ratio:  210/297;
}

.order-card .cont {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    position: relative;
}

.order-card .symbol {
    height: 40px;
    position: relative;
    width: 40px;
}

.order-card .overlap-group {
    background-size: 100% 100%;
    height: 40px;
    left: 3px;
    position: relative;
    width: 35px;
}

.order-card .star {
    position: relative;
    height:auto;
    width: 44px;
}

.order-card .date {
    color: var(--jet-black);
    font-family: "HK Grotesk-RegularLegacy", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    opacity: 0.4;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.order-card .text-wrap {
    color: var(--jet-black);
    font-family: "HK Grotesk-Bold", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18px;
    position: relative;
    width: fit-content;
}

.order-card .text-wrapper {
    font-weight: 700;
}

.order-card .span {
    font-family: "HK Grotesk-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    line-height: 23.7px;
}

.order-card .button-secondary {
    align-items: center;
    background-color: var(--jet-black);
    display: inline-flex;
    flex: 0 0 auto;
    gap: 20px;
    padding: 14px 20px;
    position: relative;
}

.order-card .div {
    color: var(--isabeline);
    font-family: "HK Grotesk-RegularLegacy", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.order-card .status {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 5px;
    position: relative;
}

.order-card .hedding {
    color: var(--jet-black);
    font-family: "HK Grotesk-RegularLegacy", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    opacity: 0.5;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.order-card .flex-text {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    position: relative;
}

.order-card .icon {
    height: 20px;
    position: relative;
    width: 20px;
}

.order-card .text {
    color: var(--jet-black);
    font-family: "HK Grotesk-SemiBoldLegacy", Helvetica;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

@media screen and (max-width:768px) {
    .order-card {
        gap:14px;
    }
    
    .order-card .poster {
        background-color: var(--jet-black);
        position: relative;
        width: 168px;
    }
    
    .order-card .cont {
        align-items: flex-start;
        display: inline-flex;
        flex: 0 0 auto;
        flex-direction: column;
        height: 100%;
        justify-content: space-evenly;
        position: relative;
    }
    
    
.order-card .star {
    position: relative;
    height:auto;
    width: 40px;
}
    
    .order-card .date {
        font-size: 10px;
    }
    
    .order-card .text-wrap {
        color: var(--jet-black);
        font-family: "HK Grotesk-Bold", Helvetica;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 18px;
        position: relative;
        width: fit-content;
    }
    
    .order-card .text-wrapper {
        font-weight: 700;
    }
    p{
        margin: 0;
    }
    .order-card .span {
        font-family: "HK Grotesk-Medium", Helvetica;
        font-size: 8px;
        font-weight: 500;
        line-height:148.02%;
    }
    
    
    .order-card .status {
        align-items: flex-start;
        display: inline-flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 1px;
        position: relative;
    }
    
    .order-card .hedding {
        color: var(--jet-black);
        font-family: "HK Grotesk-RegularLegacy", Helvetica;
        font-size: 8px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: normal;
        margin-top: -1px;
        opacity: 0.5;
        position: relative;
        white-space: nowrap;
        width: fit-content;
    }
    
    .order-card .flex-text {
        align-items: center;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 2px;
        position: relative;
    }
    
    .order-card .icon {
        height: 16px;
        position: relative;
        width: 16px;
    }
    
    .order-card .text {
        color: var(--jet-black);
        font-family: "HK Grotesk-SemiBoldLegacy", Helvetica;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: normal;
        position: relative;
        white-space: nowrap;
        width: fit-content;
    }
}