.button-secondary {
  align-items: center;
  border: 1px solid;
  border-color: #333332;
  display: flex;
  gap: 20px;
  justify-content: center;
  padding: 20px 40px;
  position: relative;
  min-width: 210px;
  max-width: fit-content;
  border-radius: 100px;
}

.button-secondary .red{
  background-color: var(--persian-red);
}

.button-secondary .text-wrapper {
  color: #333332;
  font-family: 'HK Grotesk-RegularLegacy' Helvetica;
  font-size: 16px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  text-transform: none;
  width: fit-content;
}

.button-secondary:hover {
  background-color: var(--jet-black);

  border-color: var( --isabeline);
}

.button-secondary:hover .text-wrapper {
  color: #fcfcfc;
}




.button-primary {
  align-items: center;
  border: 1px solid;
  border-color: #333332;
  background-color: var(--newjet-black);
  display: flex;
  gap: 20px;
  justify-content: center;
  padding: 20px 40px;
  position: relative;
  min-width: 200px;
  width: 100%;
  border-radius: 100px;
  max-width: 425px;
  margin: auto;
 cursor: pointer;
}

.button-secondary .red{
  background-color: var(--jet-black);
}

.button-primary .text-wrapper {
  color: #fcfcfc !important;
  font-family: 'HK Grotesk-Medium' Helvetica;
  font-size: 16px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  text-transform: none;
  width: fit-content;
}

.button-primary-small {
  align-items: center;
  border: 1px solid;
  border-color: #333332;
  background-color: var(--newjet-black);
  display: flex;
  gap: 20px;
  justify-content: center;
  padding: 20px 40px;
  position: relative;
  min-width: 200px;
  width: 100%;
  border-radius: 100px;

}

.button-primary-small .text-wrapper {
  color: #fcfcfc;
  font-family: 'HK Grotesk-Medium' Helvetica;
  font-size: 16px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  text-transform: none;
  width: fit-content;
}

.button-primary:hover {
  background-color: var(--isabeline);
  border-color: var( --newjet-black);
}

.button-primary:hover .text-wrapper {
  color: #333333 !important;
}

.button-underline{
  width: fit-content;
  font-family: 'HK Grotesk-Medium';
  font-size: 18px;
  padding: 6px 0px;
  margin: auto;
  color: var(--jet-black-2);
  display: flex;
  gap: 12px;
  justify-content: baseline;
  align-items: center;
}
.button-underline svg{
  height: 18px;
  width: 18px;
}

@media screen and (max-width: 768px) {
  .button-underline{
    font-size: 10px;
    padding: 3px 0px;
    gap: 5px;
  }
  .button-underline svg{
    height: 14px;
    width: 14px;
  }
  .button-secondary {
      align-items: center;
      border: 1px solid;
      border-color: var(--newjet-black);
      display: inline-flex;
      gap: 20px;
      justify-content: center;
      padding: 16px 20px;
      position: relative;
    }
    
    
    .button-secondary .text-wrapper {
      color: var(--newjet-black);
      font-family: 'HK Grotesk-RegularLegacy', Helvetica;
      font-size: 10px;
      letter-spacing: 0;
      line-height: normal;
      margin-top: -1px;
      position: relative;
      text-align: center;
      white-space: nowrap;
      width: fit-content;
    }
    .center{
      margin: auto;
    }
    .hidden{
      display: none;
    }
    .button-primary {
      align-items: center;
      border: 1px solid;
      border-color: var(--newjet-black);
      display: inline-flex;
      gap: 20px;
      justify-content: center;
      padding: 16px 30px;
      position: relative;
    }
    
    .button-primary .text-wrapper {
      color: var(--isabeline);
      font-family: 'HK Grotesk-Regular', Helvetica;
      font-size: 10px;
      letter-spacing: 0;
      line-height: normal;
      margin-top: -1px;
      position: relative;
      text-align: center;
      white-space: nowrap;
      width: fit-content;
    }
    .button-primary-small {
      align-items: center;
      border: 1px solid;
      border-color: var(--newjet-black);
      display: inline-flex;
      gap: 20px;
      justify-content: center;
      padding: 16px 30px;
      position: relative;
    }
    .button-primary:hover {
      background: var(--newjet-black);
      border-color: var( --newjet-black);
      opacity: 0.8;
    }
    
    .button-primary:hover .text-wrapper {
      color: var(--isabeline) !important;
    }
    
    .button-primary-small .text-wrapper {
      color: var(--isabeline);
      font-family: 'HK Grotesk-Regular', Helvetica;
      font-size: 10px;
      letter-spacing: 0;
      line-height: normal;
      margin-top: -1px;
      position: relative;
      text-align: center;
      white-space: nowrap;
      width: fit-content;
    }
    
}

@media screen and (max-width: 480px) {
  .button-secondary .text-wrapper {
      font-size: 12px; /* Further adjust font size for even smaller screens */
  }
}

