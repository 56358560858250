.form{
    display: flex;
    flex-direction: column;
    gap: 50px;    
    margin: auto;
    width: 80%;
}

.break-in-desktop{
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 60px;
}

.side-img{
  max-width: 430px;
  height: auto;
  border-radius: var(--br-1);
}

.form .lable{
    color: var(--Jet-Black, #333332);
    font-family: 'HK Grotesk-Medium';
    font-size: 18px !important;
    font-style: normal;
    line-height: 123.023%;
    text-align: center;
    width: fit-content;
    height: auto;
    display: flex;
    align-items: center;
    justify-self: center;
    gap: 10px;
}

.form .sequence{
    display: flex;
    overflow: hidden;
    padding: 4px 10px;
    border: 1px solid var(--Jet-Black, #333332);
    align-items: flex-start;
    gap: 10px;
    width: fit-content;
    color: var(--Jet-Black, #333332);
    font-family: HK Grotesk-Medium;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 137.023%;
}

.form .form-group{
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 15px !important;  
    width: 100%;  
}
.form .form-group input{
    padding: 0px !important;
    position: relative;;
    font-size: 18px !important;
    border: none;
    font-family: 'HK Grotesk-Medium' !important;
    outline: none;
    background-color: transparent;
    width: 100%;
    border-bottom: 0.5px solid var(--jet-black-3) !important;
}

.form .g2{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.form .g2 .form-group{
    width: 45%;
}

.form .button-radio {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
 .form .button-radio input[type="radio"] {
    display: none;
  }
  
 .form .button-radio label {
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid var(--Jet-Black, #333332);
    color: var(--Jet-Black, #333332);
    text-align: center;
    cursor: pointer;
    padding: 16px 20px;
    font-size: 22px;
    font-family: 'HK Grotesk-Medium';
    
  }
  
 .form .button-radio input[type="radio"]:checked + label {
    background-color: #333332;
    color: #fff;
  }
  .form input[type="file"] {
    position: relative;
  }
  
  .form input[type="file"]::file-selector-button {
    width: 136px;
    color: transparent;
  }
  
  
  /* ------- From Step 1 ------- */
  
  /* file upload button */
  .form input[type="file"]::file-selector-button {
    display: none;
  }
  
  /* file upload button hover state */
  .form input[type="file"]::file-selector-button:hover {
    background-color: #f3f4f6;
  }
  
  /* file upload button active state */
  .form input[type="file"]::file-selector-button:active {
    background-color: #e5e7eb;
  }
  .chackbox-group{
    width:100% !important;
    margin:auto;
    display:flex;
    gap:9px;
    justify-content: center;
  }
  .chackbox-group lable{
    font-family:"HK Grotesk-Medium";
    font-size:14px;
    opacity:0.5
  }

  input[type=checkbox] {
      border-radius: 0px;
      background-color: black;
  }
  .sample-break{
    display: flex;
    align-items: start;
    justify-self: start;
  }
  .sample-break img{
    margin-top: 0px !important;
    width: 30% ;
  }

@media screen and (max-width :768px) {
  .sample-break{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .sample-break img{
    width: 60%;
  }

  .break-in-desktop{
    display: initial;
    width: 100%;
    align-items: initial;
    gap: initial;
  }
  
  .side-img{
    display: none;
  }
    .form{
           gap: 20px;
    }
    
    .form .lable{
        font-size: 12px !important;
        line-height: 123.023%;
    }
    
    .form .sequence{
        font-size: 10px;
        padding: 10px;
        
    }
    
    .form .form-group{
       gap: 10px !important;
       width: 100%;
    }
    .form .form-group input{
        padding: 0px !important;
        position: relative;;
        font-size: 12px !important;
        width: 100%;
    }
    
    .form .g2{
        flex-direction: column;
        gap: 20px;
    }

    .form .g2 .form-group{
        width: 100%;
    }
    
    .form .button-radio {

    }
      
     .form .button-radio label {

        font-size: 14px !important;
        line-height: 137.023%;
        padding: 10px 16px;
        
      }
      .chackbox-group{
        width:80%;
        margin:auto;
        display:flex;
        gap:7px
      }
      .chackbox-group label{
        font-family:"HK Grotesk-MediumLegacy";
        font-size:10px;
        opacity:0.5
      }
}