.socials {
    align-items: center;
    display: flex;
    position: relative;
    transition: 0.2s;
}

.socials .text-wrapper {
    letter-spacing: 0;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.socials .arrow-outward {
    height: 30px;
    margin-bottom: -0.5px;
    margin-top: -0.5px;
    position: relative;
    width: 30px;
}

.socials.default {
    border: 2px solid;
    border-color: var(--isabeline);
    gap: 10px;
    padding: 20px 30px;
    width: 338px;
}

.socials.variant-4 {
    border: 1px solid;
    border-color: var(--isabeline);
    gap: 10px;
    height: 46px;
    padding: 16px 36px;
    width: 127px;
}

.socials.variant-2 {
    background-color: var(--persian-red);
    border: 2px solid;
    border-color: var(--persian-red);
    gap: 118px;
    height: 89px;
    padding: 30px 40px;
    width: 338px;
}

.socials.default .text-wrapper {
    color: var(--isabeline);
    font-family: "HK Grotesk-SemiBoldLegacy", Helvetica;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    margin-top: -2px;
}

.socials:hover
{
    background-color: var(--isabeline);
   
}

.buttonaero
{
    opacity: 0;
    transition: 0.2s;

   
}
.socials:hover .text-wrapper
{
    color: var(--persian-red);
    
}

.socials:hover .buttonaero
{
    opacity: 1;
    
}


/* Media Queries for .socials.default */
@media screen and (max-width: 768px) {
    .socials.default {
        width: 100%; /* Adjust the width for smaller screens */
    }
    .socials:hover .buttonaero
{
    opacity: 0;
    
}
.socials:hover
{
    background-color: var(--jetblack);
   
}
}

@media screen and (max-width: 480px) {
    .socials.default { 
        width: 45%;
        padding: 10px 20px; /* Adjust padding for smaller screens */
        border: 1px solid;
        border-color: var(--isabeline);
    }
    .socials.default .text-wrapper {
        color: var(--isabeline);
        font-family: "HK Grotesk-SemiBoldLegacy", Helvetica;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        margin-top: -2px;
        
    }
}


.socials.variant-4 .text-wrapper {
    color: var(--isabeline);
    font-family: "HK Grotesk-SemiBoldLegacy", Helvetica;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.4px;
    margin-top: -1px;
}

.socials.variant-2 .text-wrapper {
    color: var(--isabeline);
    font-family: "HK Grotesk-MediumLegacy", Helvetica;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    margin-top: -2px;
}
