.top-img-group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  padding: 20px;
}
.carousel-inner .skeleton-image{
  width: 100%;
  aspect-ratio: 350/280;
  background: linear-gradient(90deg, #ccc 25%, #ddd 50%, #ccc 75%);
  background-size: 200% 100%;
  animation: loadingAnimation 1.5s infinite ease-in-out;
}
@keyframes loadingAnimation {
  0% {
    background-position: -200%;
  }
  100% {
    background-position: 200%;
  }
}
.god
{
  background-position: center !important;
  background-size: 100% !important;

}
.top-img-group .frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  width: 364.84px;
 
}

.top-img-group .imgB {
  position: relative;
  width: 100%;
  aspect-ratio: 91/92;
  background-color: #dcdcdc;
  
}

.top-img-group .imgS {
  position: relative;
  width: 100%;
  aspect-ratio: 28/17;
  background-color: #dcdcdc;
  
}

.top-img-group .frame-3 {
  position: relative;
  width: 364.84px;
  aspect-ratio: 364/605;
  background-color: #dcdcdc;
  
}
.carousel-image {
  /* height: 700px; */
  max-width: 600px;
  margin: auto;
   /* Adjust the height as needed */
  object-fit: cover; /* This property ensures that the image retains its aspect ratio and covers the entire container */
}

@media (max-width: 768px) {
  .top-img-group {
      gap: 4px;
  }

    .top-img-group .frame {
      gap: 4px;
    }

    .carousel-image {
      /* height: 500px; */
      /* This property ensures that the image retains its aspect ratio and covers the entire container */
    }
}