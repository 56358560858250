.dashboard-body .alert {
    padding: 0;
    background-color: rgb(183 62 51 / 46%);
    border-radius: var(--br-1);
    padding: 4px;
    padding-left: 14px;
    font-size: 12px;
    margin: -20px 0px;
    font-family: 'HK Grotesk-Regular';
    
}

.banner-cont{
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
}

.main-banner {
    width: 100%;
    flex-shrink: 0;
    aspect-ratio: 1021/815;
    background-color: var(--jet-black);
    background-size: 100% auto;
    background-position: center;
    /* background-position-y: -160px; */
    border-radius: var(--br-1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
    padding: 40px;
    position: relative;
    max-width: 45%;
    min-width: 470px;
}


.main-banner2 {
    width: 100%;
    flex-shrink: 0;
    aspect-ratio: 1021/815;
    background-color: var(--jet-black);
    background-size: 100% auto;
    background-position: center;
    /* background-position-y: -160px; */
    border-radius: var(--br-1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
    padding: 40px;
    position: relative;
    max-width: 45%;
    min-width: 470px;
}

.main-banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    height: 100%;
    background:  linear-gradient(169deg, rgba(51, 51, 50, 0.00) 19.48%, rgba(18, 18, 18, 0.33) 51.34%, rgba(0, 0, 0, 0.57) 91.27%);
  }
.main-banner .heading{
    color: var(--Isabeline, #F7F5F2);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'HK Grotesk-SemiBold', Helvetica;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    z-index: 1;
    text-wrap: nowrap;
    white-space: nowrap;

    
}

.main-banner .sub_heading{
    color: var(--Isabeline, #F7F5F2);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:'HK Grotesk-Regular';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
z-index: 1;
    
}

.main-banner .button{
    display: flex;
    padding: 11px 30px;
    align-items: center;
    gap: 20px;
    font-family:'HK Grotesk-Regular';
    color: var(--isabeline);
    background: rgba(247, 245, 242, 0.40);
    backdrop-filter: blur(0.1px);
    border: 0.5px solid #fcfcfcaa;
    width: fit-content;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 10px;
    z-index: 1;
    border-radius: var(--br-max);

}

.banner-2{
width: 100%;
display: flex;
gap: 80px;
align-items: center;
}

@media screen and (max-width:768px) {
    .main-banner{
        width: 100%;
        aspect-ratio: 316/253;

        background-color: var(--jet-black);
        background-size: auto 100%;
        /* background-position-y: -46px; */
        gap: 3px;
        padding: 20px;
        max-width: 100%;
        min-width: 0px;
    }
    .main-banner2{
        width: 100%;
        aspect-ratio: 316/253;

        background-color: var(--jet-black);
        background-size: auto 100%;
        /* background-position-y: -46px; */
        gap: 3px;
        padding: 20px;
        max-width: 100%;
        min-width: 0px;
    }

    .main-banner .heading{
        font-size: 20px;
    }
    
    .main-banner .sub_heading{
        font-size: 12px;
    }
    
    .main-banner .button{
        font-size: 8px;
        display: flex;
padding: 10px 12.5px;
justify-content: center;
align-items: center;
    }
    .banner-2{
        gap: 45px;
        }
        
}

