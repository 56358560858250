.flex-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap; /* Ensures items are in a row */
    overflow-x: auto;  /* Horizontal scrollbar if needed */
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: fixed;
    z-index: -3;
    top:0;
    opacity: 0.15;
}
  
  .flex-row {
    display: flex;
  }
  .rhombus {
    height: 103px;
    width: 103px;
    flex-shrink: 0;
    padding: 20px;
    border: 0.5px dashed #333332;
    overflow: hidden;
    position: relative;
    transform: rotate(45deg);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    margin-bottom: 30px;
  }
  
  .rhombus img {
    width: 100%;
    height: 100%;
    transform: rotate(-45deg);
  }
  .canvas-cont{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 20px;
    gap: 30px;
    height: 100%;
    width: 100%;
    z-index: -1;
    top:0;
    background-color: #333332;
  }
  .canvas-cont canvas{
    position: relative;
    width: 80%;
    aspect-ratio: 730/654;
    max-width: 540px;
    transition: 0.5s;
  }
  .canvas-cont .heading{
      transform: translateX(800px);
      color: #D9D9D9;
      font-family: 'freight-big-pro';
      font-size: 128px;
      font-style: normal;
      font-weight: 400;
      line-height: 91.736%;
      width: 80%;
      max-width: 600px;
      text-align: right;
      text-transform: capitalize;

  }
  .canvas-cont .down-dir{
    width: 80%;
    max-width: 600px;
    z-index: -2;

  }
  .canvas-cont .down-dir img{
    height: 74px;
    width: 74px;
    margin-top: -194px;
    margin-left: 144px;
  }
 .scroll-down {
    opacity: 1;
    -webkit-transition: all .5s ease-in 3s;
    transition: all .5s ease-in 9s;
  }
  
  .scroll-down {
    position: fixed;
    margin-left: -16px;
    display: block;
    width: 32px;
    height: 32px;
    opacity: 1;
    background-size: 14px auto;
    border-radius: 50%;
    z-index: 0;
    -webkit-animation: bounce 2s infinite 2s;
    animation: bounce 2s infinite 2s;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
  }
  
  .scroll-down:before {
      position: absolute;
      top: calc(50% - 8px);
      left: calc(50% - 6px);
      transform: rotate(-45deg);
      display: block;
      width: 12px;
      height: 12px;
    opacity: 0.3;

      content: "";
      border: 2px solid #ffffff;
      border-width: 0px 0 2px 2px;
  }


  .final-vid-bg{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -10;
  }

  .final-vid-bg video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.3);
  }

  .preserving-mem {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 20px;
    height: 95.4px;
    position: relative;
    transition: 0.5s;

}

.preserving-mem .text {
    align-self: stretch;
    color: var(--isabeline);
    font-family: 'freight-big-pro';
    font-size: 32px;
    font-weight: '600';
    position: relative;
    text-align: center;
}

.preserving-mem .but-cont {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 16px;
    justify-content: center;
    position: relative;
    width: 100%;
}

.preserving-mem .but-outine {
    align-items: center;
    background-color: #fffffe0d;
    border: 0.8px solid;
    border-color: var(--isabeline);
    border-radius: 80px;
    display: inline-flex;
    gap: 4px;
    justify-content: center;
    overflow: hidden;
    padding: 11.2px 32px;
    position: relative;
    color: var(--isabeline);
    font-family: 'HK Grotesk-Medium';
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    white-space: nowrap;
}

.preserving-mem svg {
    height: 4.51px;
    position: relative;
    width: 7.96px;
    flex-shrink: 0;
}

.preserving-mem .but-solid {
    align-items: center;
    background-color: var(--isabeline);
    border: 0.8px solid;
    border-color: var(--isabeline);
    border-radius: 80px;
    display: flex;
    gap: 16px;
    justify-content: center;
    overflow: hidden;
    padding: 11.2px 32px;
    position: relative;
    width: 100%;
    font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 140%;
font-family: 'HK Grotesk-Medium';

}



  
  

  
  @keyframes bounce {
    0%,
    100%,
    20%,
    50%,
    80% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-6px);
      -ms-transform: translateY(-6px);
      transform: translateY(-6px);
    }
    60% {
      -webkit-transform: translateY(-3px);
      -ms-transform: translateY(-3px);
      transform: translateY(-3px);
    }
  }
@media screen and (max-width:769px) {
    .rhombus {
        height: 43px;
        width: 43px;
        flex-shrink: 0;
        padding: 10px;
        border: 0.5px dashed #333332;
        overflow: hidden;
        position: relative;
        transform: rotate(45deg);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        margin-bottom: 10px;
      }
      .canvas-cont{
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
        z-index: -1;
        gap: 0px;
        top:0;
        background-color: #333332;
      }
      .canvas-cont .heading{
        font-size: 59px;
        
      }
      .canvas-cont .down-dir img{
        height: 74px;
        width: 74px;
        margin-top: -154px;
        margin-left: 44px;
      }
      .preserving-mem {
        gap: 12px;
      }
      .preserving-mem .text {
        font-size: 18px;
      }
      .preserving-mem .but-outine {
        font-size: 10px;
      }
      .preserving-mem .but-solid {
        font-size: 10px;
      }
}
  