.price-card {
    align-items: center;
    background-color: transparent;
    display: inline-flex;
    flex-direction: column;
    border: 1px solid var(--jet-black-2);
    gap: 40px;
    height: 757px;
    padding: 40px 0px;
    position: relative;
    width: 360px;
    margin-top: 60px;
    color: var(--jet-black);
    border-radius: var(--br-1);
}

.price-card .head {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    padding: 0px 20px 0px 0px;
    position: relative;
    width: 100%;
}

.price-card .title {
    align-items: center;
    background-color: rgba(52, 52, 52, 0.2);
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    padding: 16px 32px;
    position: relative;
    border-radius: 0px 4px 4px 0px;
}

.price-card .head .text-wrapper {
    font-family: freight-big-pro;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: fit-content;
}

.price-card .icon {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
}

.price-card .star {
    height: 40px;
    position: relative;
    width: 40px;
}

.price-card .cont {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 0px 40px;
    position: relative;
    width: 100%;
}

.price-card .text-wrap {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 34px;
    position: relative;
    width: 100%;
}

.price-card .discription {
    align-self: stretch;
    font-family: "HK Grotesk-LightLegacy", Helvetica;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    opacity: 0.5;
    position: relative;
}

.price-card .each-magazine {
    font-family: "HK Grotesk-SemiBoldLegacy", Helvetica;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.price-card .bullets-cont {
    align-items: flex-start;
    align-self: stretch;
    border-color: var(--jet-black);
    border-top-style: solid;
    border-top-width: 1px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    padding: 32px 0px;
    position: relative;
    width: 100%;
}

.price-card .bullet {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
}

.price-card .check-circle {
    height: 30px;
    position: relative;
    width: 30px;
    filter: brightness(0.3);
}

.price-card .div {
    font-family: "HK Grotesk-RegularLegacy", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    opacity: 0.8;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

@media screen and (max-width: 768px) {
    .price-card {
        gap: 22px;
        height: auto;
        padding: 20px 0px;
        width: 297px;
        margin: 0 !important;
    }

    .price-card .title {
        align-items: center;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 10px;
        justify-content: center;
        padding: 16px 32px;
        position: relative;
    }

    .price-card .head .text-wrapper {
        font-size: 18px;
    }

    .price-card .star {
        height: 40px;
        position: relative;
        width: 40px;
    }

    .price-card .cont {
        padding: 0px 20px;
    }

    .price-card .text-wrap {
        align-items: flex-start;
        align-self: stretch;
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 22px;
        position: relative;
        width: 100%;
    }

    .price-card .discription {
        align-self: stretch;
        font-family: "HK Grotesk-RegularLegacy", Helvetica;
        font-size: 12px;
        letter-spacing: 0;
        line-height: normal;
        margin-top: -1px;
        opacity: 0.5;
        position: relative;
    }

    .price-card .each-magazine {
        font-family: "HK Grotesk-SemiBoldLegacy", Helvetica;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0px;
        line-height: normal;
        position: relative;
        white-space: nowrap;
        width: fit-content;
    }

    .price-card .bullets-cont {
        align-items: flex-start;
        align-self: stretch;
        border-color: var(--jet-black);
        border-top-style: solid;
        border-top-width: 1px;
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 7px;
        padding: 32px 0px;
        position: relative;
        width: 100%;
    }

    .price-card .bullet {
        align-items: center;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 8px;
        position: relative;
    }

    .price-card .check-circle {
        height: 18px;
        position: relative;
        width: 18px;
    }

    .price-card .div {
        font-family: "HK Grotesk-RegularLegacy", Helvetica;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: normal;
        opacity: 0.8;
        position: relative;
        white-space: nowrap;
        width: fit-content;
    }
}
