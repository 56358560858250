.dashboard-body .form{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form .lable{
    color: var(--Jet-Black, #333332);
    font-family: 'HK Grotesk-Medium';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 123.023%;
    text-align: center;
    width: fit-content;
    height: auto;
    display: flex;
    align-items: center;
    justify-self: center;
    gap: 10px;
}

.form .sequence{
    display: flex;
    overflow: hidden;
    padding: 4px 10px;
    border: 1px solid var(--Jet-Black, #333332);
    align-items: flex-start;
    gap: 10px;
    width: fit-content;
    color: var(--Jet-Black, #333332);
    font-family: HK Grotesk-Medium;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 137.023%;
}

.form .form-group{
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 15px;  
    width: 100%;  
}
.form .form-group input{
    padding: 10px;
    position: relative;;
    font-size: 20px;
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
    border-bottom: 1px solid black;
}

.form .g2{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.form .g2 .form-group{
    width: 45%;
}

.form .button-radio {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
 .form .button-radio input[type="radio"] {
    display: none;
  }
  
 .form .button-radio label {
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid var(--Jet-Black, #333332);
    color: var(--Jet-Black, #333332);
    text-align: center;
    cursor: pointer;
    padding: 16px 20px;
    font-size: 22px;
    font-family: 'HK Grotesk-Medium';
    
  }
  
 .form .button-radio input[type="radio"]:checked + label {
    background-color: #333332;
    color: #fff;
  }
  
  .form .uplodePhoto{
    width: 209px;
    height: 205px;
    background: var(--Jet-Black, #333332);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: var(--br-1);
    overflow: hidden;

  }

  .form .profileLable{
    position: relative;
    height: 100%;
    width: 100%;
    background-color: #33333223;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 18px;
    color: var(--Isabeline, #F7F5F2);
    font-family: "HK Grotesk-RegularLegacy";
  }

@media screen and (max-width :768px) {
    .form{
           gap: 20px;
    }
    
    .form .lable{
        font-size: 12px;
        line-height: 123.023%;
    }
    
    .form .sequence{
        font-size: 10px;
        padding: 10px;
        
    }
    
    .form .form-group{
       gap: 7px;
       width: 100%;
    }
    .form .form-group input{
        padding: 5px;
        position: relative;;
        font-size: 12px;
        width: 100%;
    }
    
    .form .g2{
        flex-direction: column;
        gap: 20px;
    }

    .form .g2 .form-group{
        width: 100%;
    }
    
    .form .button-radio {

    }
      
     .form .button-radio label {

        font-size: 14px;
        line-height: 137.023%;
        padding: 10px 16px;
        
      }

      .form .uplodePhoto{
        width: 104px;
        height: 104px;
        background: var(--Jet-Black, #333332);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
      }
    
      .form .profileLable{
        position: relative;
        height: 100%;
        width: 100%;
        background-color: #33333223;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 10px;
        color: var(--Isabeline, #F7F5F2);
        font-family: "HK Grotesk-RegularLegacy";
      }
      
}