.explore-card-cont{
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    gap: 40px;
    justify-content: center;
}

.explore-card {
    align-items: flex-start;
    background: rgba(51, 51, 50, 0.05);
    display: inline-flex;
    flex-direction: column;
    gap: 20px;
    padding: 24px 20px;
    position: relative;
    border-radius: var(--br-1);

  }
  
  .explore-card .heading {
    align-self: stretch;
    color: var(--jet-black);
    font-family: freight-big-pro, Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
  }
  
  .explore-card .image {
    object-fit: cover;
    position: relative;
    width: 100%;
    aspect-ratio: 266/268px;
    border-radius: var(--br-1);
  }

  .explore-card .skeleton-placeholder {
    width: 100%; /* Set the width as needed */
    height: 300px; /* Set the height as needed */
    background-color: #e0e0e0; /* Set a background color for the skeleton */
    animation: loading 1.5s infinite; /* Add a simple loading animation */
  }
  
  /* Hide the image initially, and show it when loaded */
  .explore-card .image {
    opacity: 0;
    transition: opacity 0.5s;
  }
  
  .explore-card .image.visible {
    opacity: 1;
  }
  
  
  .explore-card .explore-text {
    color: var(--jet-black-3);
    font-family: "HK Grotesk-RegularLegacy", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 140%;
    position: relative;
  }
  
  .explore-card .link {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 3px;
    position: relative;
    width: 100%;
  }
  
  .explore-card .text {
    color: var(--jet-black-2);
    font-family: "HK Grotesk-Medium", Helvetica;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.25px;
    position: relative;
    width: fit-content;
  }
  
  .explore-card svg {
    height: 18px;
    position: relative;
    width: 18px;
  }

  .feature-cont{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    padding: 40px 40px;
    border-radius: var(--br-1);
    max-width: 1180px;
    margin: auto;
  }

  .desktop-features {
    align-items: center;
    border: 0.5px solid var(--jet-black-2);
    border-radius: var(--br-1);
    display: inline-flex;
    flex-direction: column;
    gap: 16.88px;
    justify-content: center;
    padding: 25px 25px;
    position: relative;
  }
  
  .desktop-features .icon {
    height: 50px;
    position: relative;
    width: 50px;
  }
  
  .desktop-features .heading {
    align-self: stretch;
    color: var(--jet-black-2);
    font-family: "HK Grotesk-SemiBold", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    position: relative;
    text-align: center;
    
  }
  
  .desktop-features .sub-heading {
    color: var(--jet-black-4);
    font-family: "HK Grotesk-RegularLegacy", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    position: relative;
    max-width: 240px;
    text-align: center;
  }
  

  @media screen and (min-width:769px) {
    .explore-card-cont{
       
    }
    
    .explore-card {
        padding: 25px 25px;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        width: 30%;
      }
      
      .explore-card .heading {
        font-size: 20px;
      }
      
      .explore-card .image {
        aspect-ratio: 1/1;
      }
      
      .explore-card .explore-text {
       font-size: 14px;
      }
      
      .explore-card .link {
        
      }
      
      .explore-card .link .text {
        font-size: 16px;
      }
  }

  @media screen and (max-width:768px) {
.feature-cont{
  gap:20px;
  padding: 8px 0px;
  justify-content: center;


}

    .desktop-features {
      align-items: center;
      display: inline-flex;
      flex-direction: column;
      gap: 12px;
      justify-content: center;
      padding: 16px 22px;
      position: relative;

    }
    
    .desktop-features .icon {
      height: 30px;
      position: relative;
      width: 30px;
    }
    
    .desktop-features .heading {
      font-size: 12px;
    }
    
    .desktop-features .sub-heading {
      font-size: 10px;
      max-width: 120px;
    }
  }
  