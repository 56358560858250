
.container-desktop{
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 10px;
    gap: 2rem;
}
.container-mobile{
    display: none;
}
.decor-card {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 40.49px;
    position: relative;
    width: 45%;
}

.decor-card .img-cont {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24.3px;
    position: relative;
    width: 100%;
}

.decor-card .img {
    position: relative;
    width: 100%;
    height: auto;
    border-radius: var(--br-1);
}

.decor-card .cont {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    padding: 0px 10%;
    position: relative;
    width: 100%;
}

.decor-card .headin {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.decor-card .name {
    color: var(--newjet-black);
    font-family: "Proxima Nova-Regular", Helvetica;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 38.4px;
    margin-top: -0.81px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.decor-card .catigory {
    color: var(--newjet-black);
    font-family: freight-big-pro, Helvetica;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 57.6px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.decor-card .text-wrap {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32.4px;
    position: relative;
    width: 100%;
}

.decor-card .text {
    align-self: stretch;
    color: var(--jet-black);
    font-family: "HK Grotesk-RegularLegacy", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 140%;
    margin-top: -0.81px;
    position: relative;
    text-align: center;
    margin-bottom: 0;
}

.decor-card .button-primary-instance {
    border: 0.81px solid !important;
    flex: 0 0 auto !important;
    gap: unset !important;
    padding: 16px 32px !important;
    width: 240.54px !important;
}

.decor-card .design-component-instance-node {
    font-family: freight-big-pro, Helvetica !important;
    font-weight: 500 !important;
    margin-left: -28.23px !important;
    margin-right: -28.23px !important;
    margin-top: -0.81px !important;
}

@media screen and (max-width:768px) {
    .container-mobile{display: block;width: 100%;}
    .container-desktop{display: none;}
    .decor-card{
        width: 70%;
        margin: auto;
        gap: 30px;
        left: 15%;
    }
    .decor-card .name{
        font-size: 16px;
        line-height: normal;
    }
    .decor-card .cont {
        gap: 15px;
        padding: 0 0px;
    }
    .decor-card .catigory{
        font-size: 16px;
        line-height: normal;

    }
    .decor-card .text{
        font-size: 11px;
    }
    .decor-card .text-wrap{
        gap: 15px;
    }
}

.carousel-indicators{
    bottom: -50px;
}
