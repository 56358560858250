.looks-like-you-are
{
    color: var(--jet-black);
    font-family: freight-big-pro, Helvetica;
    font-size: 36px;
    font-weight: 400;
   
    letter-spacing: 0;
    line-height: normal;
    
    text-align: center;
     


}

.body-dynamic{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 25px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 80px;
}
.ordercheck3
{
    color: #33333280;
    font-family: "HK Grotesk-SemiBoldLegacy", Helvetica;

    font-weight: 600;
 
    letter-spacing: 0;
    line-height: normal;
   
    text-align: center;
    text-decoration: underline !important;
   
    white-space: nowrap; 
}

@media screen and (max-width:768px) {
    .looks-like-you-are{
        font-size: 16px;
    }
    .body-dynamic{
        display: flex;
        width: 100%;
        justify-content: start;
        padding: 20px;
        flex-direction: column;
        gap: 40px;
    }
}