.col-6
{
    display: flex !important;
    justify-content: center !important;
    width: fit-content !important;
}
.row
{
    justify-content: center;
    gap: 80px 0px;
}

.sample-cont{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px 20px;
}

.prod-grid-pageing{
    font-size: 18px;
}


@media screen and (max-width:786px) {
    .row{
        gap: 50px 0px;
    }
    .prod-grid-pageing{
        font-size: 12px;
    }
}