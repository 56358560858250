.dashboard-body{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    position: fixed;
    background-color: var(--isabeline);
}
.dashboard-body .top-navigation{
    display: flex;
    position: sticky;
    top: 0px;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    z-index: 99999;
    background-color: var(--isabeline);
}
 
.dashboard-body .top-navigation .navTitle {
    color: var(--jet-black);
    font-family: 'HK Grotesk-Medium';
    font-size: 20px;
    font-weight: 200;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    text-transform: capitalize;
}

.dashboard-body .top-navigation .logo{
    width: 40px;
    height: 40px;
    background: transparent;
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: var(--br-1);
    overflow: hidden;
}

.dashboard-body .side-navigation{
    display: flex;
    flex-direction: column;
    gap: 22px;
    padding:0px;
    width: 129px;
    align-items: center;
    background: rgba(51, 51, 50, 0.03);
    z-index: 99999999999;
    padding-top: 30px;

}


.dashboard-body .side-navigation .headding {
    color: var(--jet-black);
    font-family: 'raleway', Helvetica;
    font-size: 24px;
    font-weight: 200;
    letter-spacing: -3px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    margin-bottom: 35px;
}


.dashboard-body .side-navigation .button{
    display: flex;
    align-items: center;
    gap: 0px;
    color: var(--jet-black);
    font-family: 'HK Grotesk-Regular';
    font-size:10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-wrap: nowrap;
    cursor: pointer;
    width: fit-content;
    position: relative;
    transition: 0.2s;
    padding: 15px;
    opacity: 1;
    border-radius: var(--br-max);
}


.dashboard-body .side-navigation .button .text{
    width: 0px;
    overflow: hidden;
    transition: 0.3s;
}

.dashboard-body .side-navigation  img{
    height: 20px;
    width: 20px;
}

.dashboard-body .side-navigation .active img{
    filter: brightness(0);
    
}

.dashboard-body .side-navigation .active{
    background: var(--jet-black-5);
    font-family: 'HK Grotesk-Medium';
    font-weight: 400;
}

.dashboard-body .side-navigation .button:hover{
    gap: 8px;
    background-color: var(--jet-black-7);
}

.dashboard-body .side-navigation .button:hover .text{
    width: fit-content;

    
}

.dashboard-body .main{
    width: 100%;
    height: 100%;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    gap: 40px;    
    padding-bottom: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 15px;

}

.dashboard-body .hide-desktop{
    display: none;
}

.dashboard-body .hide-phone{
    display: initial;
}

.akritmik-nav{
    position: absolute; width: 100%;margin-left: 119px; height: 70px; background-color: var(--isabeline); z-index: 9999; top: 0px; left: 0px; border-bottom: 1px solid var(--jet-black-7);
}

@media screen and (max-width:768px) {
    .dashboard-body .hide-desktop{
        display: initial;
    }
    .dashboard-body .hide-phone{
        display: none;
    }
    .dashboard-body{
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        overflow-x: hidden;
        position: fixed;
    }
    .dashboard-body .side-navigation{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;
        padding: 5px;
    }
    .dashboard-body .top-navigation .navTitle {
       font-size: 24px;
       font-family: 'Raleway', serif;
       letter-spacing: -3px;
       text-transform: lowercase;
    }
    .dashboard-body .side-navigation .headding {
        display: none;
    }
    .dashboard-body .side-navigation .button{
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        color: var(--Jet-Black, #333332);
        font-family: 'HK Grotesk-Light';
        font-size:10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-wrap: nowrap;
        cursor: pointer;
        padding: 5px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        opacity: 0.5;
    }
    .dashboard-body .side-navigation .button .text{
        width: fit-content;
    }
    .dashboard-body .side-navigation .button img{
        width: 20px;
        height: auto;
    }
    .dashboard-body .side-navigation .active{
        background: transparent;
        font-family: 'HK Grotesk-Medium';
        font-weight: 400;
        background-color: transparent;
        opacity: 1;
        /* color: var(--Persian-Red, #B73E33); */
    }
    .dashboard-body .top-navigation .logo{
        width: 25px;
        height: 25px;
        background-size: 80% auto;
    background-position: center center;
    background-repeat: no-repeat;

    }
    .dashboard-body .side-navigation .button:hover{
        gap: 4px;
        background-color: transparent;
    }
    .akritmik-nav{
        position: absolute; width: 100%;margin-left: 0px; height: 50px; background-color: var(--isabeline); z-index: 9999; top: 0px; left: 0px; border-bottom: 1px solid var(--jet-black-7);
    }
}