.form{
    display: flex;
    flex-direction: column;
    gap: 50px;    
    margin: auto;
    width: 80%;
}

.form .lable{
    color: var(--Jet-Black, #333332);
    font-family: 'HK Grotesk-Regular';
    font-size: 20px;
    font-style: normal;
    line-height: 123.023%;
    text-align: center;
    width: fit-content;
    height: auto;
    display: flex;
    align-items: center;
    justify-self: center;
    gap: 10px;
}

.form .sequence{
    display: flex;
    overflow: hidden;
    padding: 4px 10px;
    border: 1px solid var(--Jet-Black, #333332);
    align-items: flex-start;
    gap: 10px;
    width: fit-content;
    color: var(--Jet-Black, #333332);
    font-family: HK Grotesk-Medium;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 137.023%;
}

.form .form-group{
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 15px;  
    width: 100%;  
}
.form .form-group input{
    padding: 10px;
    position: relative;;
    font-size: 20px;
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
    border-bottom: 0.25px solid black;
    border-radius: 0;
}

.form .g2{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.form .g2 .form-group{
    width: 45%;
}

.form .button-radio {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  .form .button-radio label{
    text-transform: capitalize;

  }
 .form .button-radio input[type="radio"] {
    display: none;
  }
  
 .form .button-radio label {
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid var(--Jet-Black, #333332);
    color: var(--Jet-Black, #333332);
    text-align: center;
    cursor: pointer;
    padding: 16px 20px;
    font-size: 22px;
    font-family: 'HK Grotesk-Medium';
    border-radius: var(--br-1);
    
  }
  
 .form .button-radio input[type="radio"]:checked + label {
    background-color: #333332;
    color: #fff;
  }
  .form input[type="file"] {
    position: relative;
  }
  
  .form input[type="file"]::file-selector-button {
    width: 136px;
    color: transparent;
  }
  
  
  /* ------- From Step 1 ------- */
  
  /* file upload button */
  .form input[type="file"]::file-selector-button {
    display: none;
  }
  
  /* file upload button hover state */
  .form input[type="file"]::file-selector-button:hover {
    background-color: #f3f4f6;
  }
  
  /* file upload button active state */
  .form input[type="file"]::file-selector-button:active {
    background-color: #e5e7eb;
  } .logo-text{
  opacity: 0.7;
  font-size: 22px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.req-break{
  display: flex;
  flex-direction: row;
  gap: inherit;
}
.req-break .left{
  max-width: 610px;
  display: flex;
  flex-direction: column;
  gap: inherit;
}
.req-break .right{
  width: 100%;
}
@media screen and (max-width :768px) {
  .req-break{
    display: flex;
    flex-direction: column;
    gap: inherit;
  }
  .req-break .left{
    width: 100%;
    gap: inherit;
  }
  .req-break .right{
    width: 100%;
  }
    .form{
           gap: 20px;
    }
    .logo-text{
      opacity: 0.7;
      font-size: 10px;
      display: flex;
      gap: 5px;
      align-items: center;
    }
    
    .form .lable{
        font-size: 12px;
        line-height: 123.023%;
    }
    
    .form .sequence{
        font-size: 10px;
        padding: 10px;
        
    }
    
    .form .form-group{
       gap: 6px;
       width: 100%;
    }
    .form .form-group input{
        padding: 5px;
        position: relative;;
        font-size: 12px;
        width: 100%;
    }
    
    .form .g2{
        flex-direction: column;
        gap: 20px;
    }

    .form .g2 .form-group{
        width: 100%;
    }
    
    .form .button-radio {

    }
      
     .form .button-radio label {

        font-size: 14px;
        line-height: 137.023%;
        padding: 10px 16px;
        
      }
      
}