.product {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 18px;
    width: 228px;
    position: relative;
}

.product .image {
    height: 320px;
    object-fit: cover;
    position: relative;
    width: 228px;
    border-radius: var(--br-1);
}
.product .skeleton-image {
    height: 320px; /* Set the width of the skeleton */
    aspect-ratio: 228/320; /* Maintain a square aspect ratio (adjust as needed) */
    background-color: #f0f0f0; /* Set the background color of the skeleton */
    animation: loadingProduct 1.5s infinite; /* Add a pulsating animation (optional) */
  }
  
  @keyframes loadingProduct {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
  
.product .text-wrap {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.product .palak-and-dharmesh {
    align-self: stretch;
    color: transparent;
    font-family: "HK Grotesk-Regular", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    margin-bottom: 0px;
    
}

.product .text-wrapper {
    color: #33333280;
    
}

.product .span {
    color: #333332;
    font-family: "HK Grotesk-Medium", Helvetica;
    font-weight: 500;
    display: block;
    overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product .learn-more {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
}
p{
    margin: 0;
}
.product .div {
    color: var(--newjet-black);
    font-family: 'HK Grotesk-MediumLegacy';
    font-weight: 500;
    font-size: 22px;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-decoration: underline;
    white-space: nowrap;
    width: fit-content;
    letter-spacing: 0px !important;
}
.product .learn-more:hover
{
    opacity: 0.6;
}
.product .arrow-outward {
    height: 24px;
    position: relative;
    width: 24px;
}
@media screen and (max-width: 768px) {
    .product{
        gap:8px;
        width: 133px;
    }
    .product .text-wrap{
        gap: 5px !important;
    }
    .product .image{
        width: 133px;
        height: auto;
    }
    .product .skeleton-image {
        height: 188px; /* Set the width of the skeleton */
        aspect-ratio: 133/188;
    }
    .product .palak-and-dharmesh{
        font-size: 12px;
    }
    .product .div {
        font-size: 12px;
        letter-spacing: 0px !important;

    }
    .product .span {
        color: #333332;
        font-family: "HK Grotesk-Medium", sans-serif;
        font-weight: 500;
        font-size: 12px;
        text-wrap: nowrap;
    }
    .product .arrow-outward {
        height: 16px;
        position: relative;
        width: 16px;

    }
    .product.text-wrap{
        gap: 14px;
    }
}