.priceD2C-cont{
    width: 100%;
    display: flex;
    gap: 30px;
    justify-content: center;
    flex-wrap: wrap;
}

.category-cont{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin: auto;
    justify-content: space-evenly;
    gap: 30px;
    max-width: 1280px;
}

.sub-category-cont{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    gap: 50px;
    max-width: 1280px;
margin: auto;
}

.order-summry{
    width: 100%;
    margin: auto;
    padding: 40px;
    padding-top: 0;
    gap: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: start;
}

.order-summry .right{  
    display: flex;
    flex-direction: column;
    gap: inherit;
    max-width: 615px;
  }
  .order-summry .left{  
    display: flex;
    flex-direction: column;
    gap: inherit;
    
  }

  .order-summry .right .proceed-button{  
    display:none;
  }

.order-summry .cont{
    width: 100%;
    display: flex;
    align-items: end;
    gap: 30px;
}

.order-summry .cont img{
    width: auto;
    height: 145px;
    border-radius: var(--br-1);
}

.order-summry .cont .text-wrap{
    display: flex;
    flex-direction: column;
    font-size: 24px;
    color: var(--jet-black);
    font-family: 'HK Grotesk-Regular';
    text-transform: capitalize;
}

.order-summry .cont .text-wrap .heading{
    font-size: 32px;
    font-family: 'HK Grotesk-Medium';
}

.order-summry .cont .text-wrap .sub-heading{
    text-transform: none;
    color: var(--jet-black-2);
}

.order-summry .bill{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 16px;
}

.order-summry .bill .cont{
    display: flex;
    justify-content: space-between;
    opacity: 0.75;
}

.order-summry .bill .cont .svg-text{
    display: flex;
    gap:10px;
    text-transform: capitalize;
}

.order-summry .bill .cont .svg-text .svg{
height: 24px;
width: 24px;
}
.order-summry .bill .cont .svg-text .text{
    font-size: 18px;
    font-family: 'HK Grotesk-RegularLegacy';
    color: var(--jet-black-3);
}

.order-summry .outline{
    border: 1px solid rgba(51, 51, 50, 0.20);;
    background-color: var(--isabelle) !important;
}

.order-summry .bill .cont .price{
    font-family: 'HK Grotesk-RegularLegacy';
    font-weight: 600;
    font-size: 18px;
    color: var(--jet-black-3);
}
.order-summry .bill .total{
    border-top: 1px solid black;
    font-family: 'HK Grotesk-RegularLegacy';
    font-weight: 600;
    padding-top: 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;

}

.order-summry .coupan-cont{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: auto;
    width: 100%;
    max-width: 640px;
}

.order-summry .coupan-cont .heading{
    font-size: 18px;
    font-family: 'HK Grotesk-SemiBold';
    font-weight: 500;
}

.order-summry .coupan-cont .input-cont{
    display: flex;
    width: 100%;
    border-radius: var(--br-1);
    overflow: hidden;
}
.order-summry .coupan-cont .input-cont input[type="text"]{
    outline: none;
    border-radius: 0;
    border: none;
    background-color: #fcfcfc;
    text-transform: uppercase;
    font-size: 18px;
    font-family: 'HK Grotesk-Regular';
    font-weight: 500;
    width: 100%;
    padding: 20px 35px;
}

.order-summry .coupan-cont .input-cont .but{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-family: 'HK Grotesk-SemiBold';
    font-weight: 500;
    padding: 20px 30px;
    color: #fff;
    background-color: var(--jet-black);
    cursor: pointer;
}

.order-summry .radio-checkout {
    align-items: flex-start;
    background-color: #efefef;
    display: flex;
    gap: 18px;
    cursor: pointer;
    padding: 50px 40px;
    position: relative;
    width: 100%;
    border-radius: var(--br-1);
  }
  
 .order-summry .radio-checkout .button {
    height: 26px;
    position: relative;
    flex-shrink: 0;
    width: 26px;
    border: 1px solid black;
    border-radius: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
 .order-summry .radio-checkout .button .fill {
    height: 70%;
    width: 70%;
    background-color: black;
    border-radius: 360px;
  }
  
 .order-summry .radio-checkout .text-wrap {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 6px;
    position: relative;
  }
  
 .order-summry .radio-checkout .heading {
    color: var(--jet-black);
    font-family: "HK Grotesk-SemiBoldLegacy", Helvetica;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: normal;
    margin-right: -15.5px;
    margin-top: -1px;
    position: relative;
    width: 269px;
  }
  
.order-summry  .radio-checkout .sub-heading {
    align-self: stretch;
    color: var(--jet-black);
    font-family: "HK Grotesk-Regular", Helvetica;  
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px; 
    line-height: 24.6px;
    opacity: 0.7;
    position: relative;
  }
  .proceed-button{
    position: relative;
    background-color: var(--jet-black);
    cursor: pointer;
    width: 100%;
    color: #fff;
    height: 55px;
    max-width: calc(715px - 80px);
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    margin-bottom: 40px;
    transition: 0.2s;
    border-radius: var(--br-max);
    font-family: "HK Grotesk-Medium";
  }
 .inform{
    background-color: var(--jet-black-4);
    font-family: "HK Grotesk-Medium";
    font-size: 12px;
    padding: 15px;
    color: #fff;
    width: 100%;
    text-align: center;
    margin-top: 0px;
  }
  @media screen and (max-width:768px) {
    .order-summry{
        width: 100%;
        padding: 30px;
        gap: 30px;
    }
 .inform{
        background-color: var(--jet-black-4);
        font-family: "HK Grotesk-Medium";
        font-size: 8px;
        padding: 10px;
        margin-top: 50px;
      }

    .sub-category-cont{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 26px;
        margin-top: 0px;
    }
    
    .category-cont{
        display: flex;
        width: 80%;
        flex-wrap: wrap;
        margin: auto;
        gap: 15px;
    }
    
    .order-summry .cont{
        width: 100%;
        display: flex;
        align-items: end;
        gap: 20px;
    }
    
    .order-summry .cont img{
        width: auto;
        height: 95px;
    }
    
    .order-summry .cont .text-wrap{
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-family: 'HK Grotesk-RegularLegacy';
    }
    
    .order-summry .cont .text-wrap .heading{
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0 !important;
    }
    
    .order-summry .cont .text-wrap .sub-heading{
        opacity: 0.6;
        letter-spacing: 0px;
    }
    
    .order-summry .bill{
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding-bottom: 8px;
    }

    
    .order-summry .bill .cont{
        display: flex;
        justify-content: space-between;
    }
    
    .order-summry .bill .cont .svg-text{
        display: flex;
        gap:4px;
    }
    
    .order-summry .bill .cont .svg-text .svg{
    height: 18px;
    width: 18px;
    }
    .order-summry .bill .cont .svg-text .text{
        font-size: 12px;
        font-family: 'HK Grotesk-RegularLegacy';
        letter-spacing: 0px;
    }
    
    .order-summry .bill .cont .price{
        font-family: 'HK Grotesk-RegularLegacy';
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0px;
    }
    .order-summry .bill .total{
        border-top: 1px solid rgba(0, 0, 0, 0.479);
        padding-top: 10px;
        text-align: right;
        font-size: 12px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-weight: 600;
    }

    .order-summry .coupan-cont{
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }

    .order-summry .coupan-cont .heading{
        font-size: 12px;
        font-family: 'HK Grotesk-SemiBold';
        font-weight: 500;
    }

    .order-summry .coupan-cont .input-cont{
        display: flex;
        width: 100%;
    }
    .order-summry .coupan-cont .input-cont input[type="text"]{
        outline: none;
        border-radius: 0;
        border: none;
        text-transform: uppercase;
        font-size: 10px;
        font-family: 'HK Grotesk-Regular';
        font-weight: 500;
        width: 100%;
        padding: 10px 15px;
        background-color: #fcfcfc;
    }

    .order-summry .coupan-cont .input-cont .but{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-family: 'HK Grotesk-SemiBold';
        font-weight: 500;
        padding: 10px 20px;
        color: #fff;
        background-color: var(--jet-black);
    }

    .order-summry .applied-coupon{
        font-size: 10px;
        font-family: 'HK Grotesk-RegularLegacy';
        font-weight: 800;
        color: var(--jet-black);
    }

    .order-summry .radio-checkout {
        align-items: flex-start;
        background-color: #efefef;
        display: flex;
        gap: 10px;
        padding: 30px 20px;
        position: relative;
        width: 100%;
      }
      
     .order-summry .radio-checkout .button {
        height: 16px;
        position: relative;
        flex-shrink: 0;
        width: 16px;
        border: 1px solid black;
        border-radius: 360px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
     .order-summry .radio-checkout .button .fill {
        height: 70%;
        width: 70%;
        background-color: black;
        border-radius: 360px;
      }
      
     .order-summry .radio-checkout .text-wrap {
        align-items: flex-start;
        display: flex;
        flex: 1;
        flex-direction: column;
        flex-grow: 1;
        gap: 10px;
        position: relative;
      }
      
     .order-summry .radio-checkout .heading {
        color: var(--jet-black);
        font-family: "HK Grotesk-SemiBoldLegacy", Helvetica;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0px;
        line-height: normal;
        margin-right: -15.5px;
        margin-top: -1px;
        position: relative;
        width: 269px;
      }
      
    .order-summry  .radio-checkout .sub-heading {
        align-self: stretch;
        color: var(--jet-black-3);
        font-family: "HK Grotesk-Regular", Helvetica;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 140%;
        position: relative;
      }
       .proceed-button{
        /* position: sticky;
        bottom: 0; */
        background-color: var(--jet-black);
        width: 100%;
        color: #fff;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99999;
        margin-bottom: 40px;
        transition: 0.2s;
      }
      .order-summry .left .proceed-button{  
        display: none
      }
      .order-summry .right .proceed-button{  
        display: flex
      }
      
  }
  .proceed-button:hover{
    background-color: var(--jet-black-2);
}