.nav {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    position: fixed;
    top:0;
    z-index: 99999999;
    width: 100%;
    background-color: transparent;
    transition: 0.5s;
    display: none;
}

.nav .menu {
    height: 42px;
    position: relative;
    width: 200px;
    font-size: 28px;
    cursor: pointer;
}

.nav .navTitle {
    color: var(--isabeline);
    font-family: 'Raleway', serif;
    font-size: 42px;
    font-weight: 200;
    letter-spacing: -3px;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.nav.scrolled .navTitle{
    color: var(--newjet-black) !important;
}

.nav.scrolled {
    background-color: var(--isabeline);
    /* box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.06); */
    border-bottom:0.5px solid var(--jet-black-7) ;
  }

  .nav-open {
    background-color: var(--persian-red);
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    gap: 50px;
    animation:0.4s navAnimation;
    transition: 0.4s;
    transform-origin: 0 0;
} 

.nav-open .frame {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 98px;
    position: relative;
    margin-left: 50px;
}

.nav-open .div {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 30px;
    position: relative;
    width: 60%;
    
}

.nav-open .frame-2 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 25px;
    position: relative;
    transition: 0.2s;
    border-bottom: 3px solid var(--persian-red);
    width: 100%;
}

a{
    text-decoration: none !important;
}

.nav-open .frame-2:hover{
    border-bottom: 3px solid white;
}

.nav-open .text-wrapper {
    color: var(--isabeline);
    font-family: "HK Grotesk-RegularLegacy", Helvetica;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.nav-open .line {
    height: 2px;
    position: relative;
    width: 907px;
}

.nav-open .img {
    height: 1px;
    margin-top: 200px;
    margin-right: 0px;
    position: absolute;
    width: 907px;
}

.nav-open .line-2 {
    height: 1px;
    margin-bottom: -2994px;
    margin-right: -1284px;
    position: relative;
    width: 907px;
}

.nav-open .line-3 {
    height: 1px;
    margin-bottom: -2903px;
    margin-right: -1284px;
    position: relative;
    width: 907px;
}

.nav-open .text-wrapper-2 {
    color: var(--isabeline);
    font-family: 'Raleway', serif;
    font-size: 200px;
    font-weight: 400;
    letter-spacing: -9px;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.nav-open .star {
    height: auto;
    right: 0;
    position: absolute;
    top: 83px;
    width: 293px;
}

.close{
    font-size: 30px;
    color: #fff;
    cursor: pointer;
}

@keyframes navAnimation {
    from {transform: translateY(-100%);}
    to {transform: translateY(0%);}
  }

  @media screen and (max-width: 768px) {
    .nav {
        display: flex;
        flex-direction: row;
    }
    .nav .navTitle {
        font-size: 24px; /* Adjust font size for mobile */
        gap: 10px;
        width: 100%;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }

    .nav-open .frame {
        gap:50px;
        margin-left: 0;
    }
    .nav-open .close{
        text-align: right;
    }
    .nav-open .frame-2 {
        border-bottom: 3px solid var(--persian-red);
    }

    .nav-open .frame-2:hover {
        border-bottom: 3px solid white;
    }

    .nav-open .text-wrapper {
        font-size: 18px; /* Adjust font size for mobile */
    }

    .nav-open .text-wrapper-2 {
        font-size: 80px; /* Further adjust font size for mobile */
    }

    .nav-open .star {
        height: auto; /* Further adjust star size for mobile */
        width: 100px; /* Further adjust star size for mobile */
        display: none;
        
    }
    .nav .menu {
        height: fit-content;
        width: fit-content;
    }
}

.fade-out{
    transform: translateY(-100%);
}
