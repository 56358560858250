.corporate {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 30px;
    position: relative;
}

.dropdown-item,.dropdown-menu
{
    background-color: var(--newisabeline) ;
}
.corporate .corporate-wrapper {
    color: var(--newjet-black);
    font-family: freight-big-pro
, Helvetica;
    font-size: 64px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.corporate .corporate-div {
    align-items: center;
    background-color: var(--jet-black-8);
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    padding: 20px 40px;
    position: relative;
    width: 451px;
    border-radius: var(--br-1);
}

.corporate .corporate-wrapper-2 {
    color: var(--jet-black);
    font-family: "HK Grotesk-MediumLegacy", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    opacity: 0.6;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.corporate .arrow-drop-up {
    height: 30px;
    position: relative;
    width: 30px;
}

.corporate .bundle {
    color: var(--persian-red);
    font-family: "HK Grotesk-MediumLegacy", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}
.down
{
    margin-left: 50%;
    
}

@media screen and (max-width: 550px)
{
    .corporate{
        gap: 15px;
    }
    .corporate-wrapper
    {
        font-size: 36px !important;
    }
    .corporate .corporate-wrapper-2 {
        font-size: 14px;
    }
    .corporate .bundle{
        font-size: 12px;
    }
    .corporate-div
    {
        width: 249px !important;
        padding: 10px 10px !important;
    }
    .down
    {
        margin-left: 0%;
    }

}