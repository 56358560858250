.feedback-new {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    position: relative;
    width: 100%;
    flex-shrink: 0;

}
.feed-desk{
    flex-direction: row-reverse;
    display: flex;
}

.feed-desk-cont{
    display: flex;
    flex-direction: column;
    min-width: 460px;
    gap: 60px;
    align-items: end;
}

.feed-desk-cont .element-prestalo-onli{
    text-align: right !important;
}

.feed-desk .rectangle {
    height: auto;
    width: 70%;
    border-radius: var(--br-1);
}

.feedback-new .frame-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    position: relative;
}

.feedback-new .text-wrapper {
    color: var(--Jet-Black, #333332);
    font-family: freight-big-pro, Helvetica, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
}

.feedback-new .element-prestalo-onli {
    color: var(--jet-black);
    font-family: "HK Grotesk-RegularLegacy", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 140%;
    position: relative;
    text-align: center;
    width: auto;
}

.feedback-new .element-prestalo-onli-2 {
    color: var(--jet-black);
    font-family: "HK Grotesk-SemiBoldLegacy Italic", Helvetica;
    font-size: 20px;
    font-style: italic;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 30px;
    position: relative;
    text-align: center;
    text-decoration: underline;
    white-space: nowrap;
    width: fit-content;
}

.feedback-new .get{
    
color: var(--Jet-Black, #333332);
font-family: 'HK Grotesk-MediumLegacy';
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 150%;
letter-spacing: 0.2px;
text-align: center;
text-decoration-line: underline !important;
}
.feedback-new p{
    margin: 0;
    padding: 0;
}

@media screen and (max-width:760px) {
    .feedback-new {
        gap: 10px;
    }
    
    .feedback-new .text-wrapper {
        color: var(--Jet-Black, #333332);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 10px;
    }
    
    .feedback-new .element-prestalo-onli {
        color: var(--jet-black);
        font-family: "HK Grotesk-RegularLegacy", Helvetica;
        font-size: 11px;
        font-weight: 400;
        letter-spacing: 0;
        position: relative;
        text-align: center;
        width: auto;
        padding: 0px 10px;
    }

    
.feedback-new .rectangle {
    height: auto;
    width: 100%;
    border-radius: var(--br-1);
}

    .feedback-new .element-prestalo-onli-2 {
        color: var(--Jet-Black, #333332);
        text-align: center;
        font-family: HK Grotesk;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 0%; /* 0px */
    }
    .feedback-new .get{
        color: var(--Jet-Black, #333332);
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-decoration-line: underline !important;
    }
    .feed-desk{
        flex-direction: column;
        display: flex;
    }
    
    .feed-desk-cont{
        display: flex;
        flex-direction: column;
        min-width: 460px;
        gap: 10px;
        min-width: 0px;
        align-items: center;
    }
    
    .feed-desk-cont .frame-2{
        justify-content: center;
    }
    .feed-desk-cont .element-prestalo-onli{
        text-align: center !important;
    }
}
