.dashboard-feed-back {
    align-items: flex-start;
    background-color: #f8f8f8;
    display: inline-flex;
    flex-direction: column;
    gap: 25.2px;
    justify-content: start;
    padding: 25px 25px;
    position: relative;
    border-radius: var(--br-1);
  }
  
  .dashboard-feed-back .rectangle {
    align-self: stretch;
    height: 347.2px;
    object-fit: cover;
    position: relative;
    width: 315px;
    border-radius: var(--br-1);

  }
  
  .dashboard-feed-back .feedback-name {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 29.4px;
    justify-content: center;
    position: relative;
  }
  
  .dashboard-feed-back .vorem-ipsum-dolor {
    color: var(--jet-black);
    font-family: "HK Grotesk-RegularLegacy", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 17.9px;
    margin-top: -1.4px;
    opacity: 0.8;
    position: relative;
    width: 100%;
  }
  
  .dashboard-feed-back .name-title {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 14px;
    justify-content: center;
    padding: 0px 20px 0px 0px;
    position: relative;
  }
  
  .dashboard-feed-back .jagrit-sachdeva {
    color: transparent;
    font-family: "HK Grotesk-SemiBold", Helvetica;
    font-size: 21.4px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 21.4px;
    margin-top: -1.4px;
    position: relative;
    width: fit-content;
  }
  
  .dashboard-feed-back .text-wrapper {
    color: #333332;
    font-weight: 600;
  }
  
  .dashboard-feed-back .span {
    color: #33333266;
    font-family: "HK Grotesk-Medium", Helvetica;
    font-weight: 500;
  }
  @media screen and (max-width:768px) {
    .dashboard-feed-back {
        align-items: flex-start;
        background-color: #f8f8f8;
        display: inline-flex;
        flex-direction: column;
        gap: 12.2px;
        justify-content: start;
        padding: 12px 12px;
        position: relative;
      }
      
      .dashboard-feed-back .rectangle {
        align-self: stretch;
        height: 171px;
        object-fit: cover;
        position: relative;
        width: 155px;
      }
      
      .dashboard-feed-back .feedback-name {
        align-items: flex-start;
        display: inline-flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 14px;
        justify-content: center;
        position: relative;
      }
      
      .dashboard-feed-back .vorem-ipsum-dolor {
        color: var(--jet-black);
        font-family: "HK Grotesk-RegularLegacy", Helvetica;
        font-size: 9px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 128%;
        margin-top: -1.4px;
        opacity: 0.8;
        position: relative;
      }
      
      .dashboard-feed-back .name-title {
        align-items: center;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 14px;
        justify-content: center;
        padding: 0px 10px 0px 0px;
        position: relative;
      }
      
      .dashboard-feed-back .jagrit-sachdeva {
        color: transparent;
        font-family: "HK Grotesk-SemiBold", Helvetica;
        font-size: 11.4px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 135%;
        margin-top: -1.4px;
        position: relative;
        width: fit-content;
      }
      
      .dashboard-feed-back .text-wrapper {
        color: #333332;
        font-weight: 600;
      }
      
      .dashboard-feed-back .span {
        color: #33333266;
        font-family: "HK Grotesk-Medium", Helvetica;
        font-weight: 500;
        letter-spacing: 0;
      }
  }