.product {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 18px;
    position: relative;
}

.product .text-wrapper {
    color: #33333280;
}



.product .learn-more {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    letter-spacing: 0;
    position: relative;
}

.product .div {
    color: var(--newjet-black);
    font-family: "HK Grotesk-SemiBoldLegacy", Helvetica;
        font-weight: 600;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-decoration: underline;
    white-space: nowrap;
    width: fit-content;
    height: 100%;
}


.product .arrow-outward {
    height: 24px;
    position: relative;
    width: 24px;
}



@media screen and (max-width: 768px) {
    .product{
        gap:8px;
    }
    .product .text-wrap{
        gap: 18px;
    }
    .product .image{
        width: 133px;
        height: auto;
    }
    .product .palak-and-dharmesh{
        font-size: 12px;
    }
    .product .div {
        font-size: 10px;
    }
    .product .arrow-outward {
        height: 12px;
        position: relative;
        width: 12px;
    }
}