
.swiper {
   width: 100%;
   padding-top: 30px;
   padding-bottom: 30px;
 }
 
 .swiper-slide {
   background-position: center;
   background-size: cover;
   width: 390px;
   height: auto;
   margin: 0 30px;
 }
 
 .swiper-slide img {
   display: block;
   width: 100%;
   border-radius: var(--br-1);
 }

 .swiper-3d .swiper-slide-shadow-left{
   opacity: 0.7;
   background-image: none;
 }
 .swiper-3d .swiper-slide-shadow-right{
   opacity: 0.7;
   background-image: none;
 }

 .swiper-pagination-bullet{
   height: 2px;
   width: 18px;
   border-radius: 20px;
 }
 .swiper-pagination-bullet-active{
   background-color: black;
 }

 .swiper-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
 }
 @media screen and (max-width:768px) {
   .swiper-slide {
      width: 250px;
      margin: 0 15px;
    }
 }