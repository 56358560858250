.process-container {
    align-items: center;
    background-color: var(--isabeline);
    display: flex;
    flex-direction: column;
    /* padding: 50px 60px; */
    position: relative;
    margin-top: 48px;
}

.process-container .swiper-slide {
    width: 487px !important;
    border-radius: 6px;
    overflow: hidden;
}
.process-container .heading {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.process-container .sub-title {
    color: var(--jet-black);
    font-family: "Proxima Nova-Regular", Helvetica;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 38.4px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: 100%;
    opacity: 0.7;
}

.process-container .title {
    color: var(--jet-black);
    font-family: freight-big-pro, Helvetica;
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 76.8px;
    position: relative;
    text-align: center;
    width: 100%;
}

.process-container .process-wrap {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    gap: 50px 150px;
    justify-content: center;
    position: relative;
    width: 100%;
}

.process-container .process {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 25px;
    justify-content: center;
    position: relative;
}

.process-container .vector {
    height: 128px;
    position: relative;
    width: 125px;
}

.process-container .text-wrap {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
}

.process-container .text-wrapper {
    align-self: stretch;
    color: var(--jet-black);
    font-family: freight-big-pro, Helvetica;
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 43.2px;
    margin-top: -1px;
    position: relative;
}

.process-container .div {
    color: var(--jet-black);
    font-family: "HK Grotesk-RegularLegacy", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    max-width: 309px;
}

.process-container .question-mark {
    height: 128.12px;
    position: relative;
    width: 125px;
}

.process-container .frame {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 129px;
    position: relative;
    width: 350px;
}

.process-container .text-wrapper-2 {
    align-self: stretch;
    color: var(--jet-black);
    font-family: ""freight-big-pro"-Book", Helvetica;
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 43.2px;
    margin-top: -1px;
    position: relative;
    text-align: center;
}

.process-container .p {
    align-self: stretch;
    color: var(--jet-black);
    font-family: "HK Grotesk-RegularLegacy", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
}
.process-container .carousel-indicators button{
    background-color: var(--jet-black);
    height: 1px;
}
@media screen and (max-width:768px) {
    .process-container {
        /* padding: 50px 30px; */
    }
    .process-container .swiper-slide {
        width: 75% !important;
        border-radius: 6px;
        overflow: hidden;
    }

    .process-container .heading {
    
    }

    .process-container .sub-title {
        text-align: center;
         
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
    }

    .process-container .title {
        text-align: center;
         
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
    }

    .process-container .process-wrap {
        
    }

    .process-container .process {
        flex-direction: column;
        justify-content: baseline;
        align-items: center;
        width: 100%;
        
    }

    .process-container .vector {
        width: 60px;
        height: auto;
    }

    .process-container .text-wrap {
    }

    .process-container .text-wrapper {
         
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; 


    }

    .process-container .div {
        font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom: 0;
max-width: 500px;

    }

    .process-container .question-mark {
    
    }

    .process-container .frame {
        
    }

    .process-container .text-wrapper-2 {
        
    }

    .process-container .p {
        
    }

}