.button-primary-price {
    align-items: center;
    border: 1px solid;
    border-color:var(--jet-black);
    display: flex;
    gap: 20px;
    justify-content: center;
    padding: 20px 40px;
    position: relative;
    width: 100%;
    background-color: var(--isabeline);
    border-radius: 80px;
  }

  .button-primary-price .red{
    background-color: var(--persian-red);
  }
  
  .button-primary-price .text-wrapper {
    color: var(--jet-black);
    font-family: "HK Grotesk-SemiBoldLegacy", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .button-primary-price:hover {
    opacity: 0.65;
  }

  @media screen and (max-width: 768px) {
    .button-primary-price {
        align-items: center;
        border: 1px solid;
        display: inline-flex;
        gap: 20px;
        justify-content: center;
        padding: 13px 20px;
        position: relative;
        width: 100%;
      }
      
      .button-primary-price .text-wrapper {
        color: var(--jet-black);
        font-family: "HK Grotesk-SemiBoldLegacy", Helvetica;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: normal;
        margin-top: -1px;
        position: relative;
        text-align: center;
        white-space: nowrap;
        width: fit-content;
      }
      .center{
        margin: auto;
      }
      .hidden{
        display: none;
      }
      
}

@media screen and (max-width: 480px) {
    .button-primary-price .text-wrapper {
        font-size: 12px; /* Further adjust font size for even smaller screens */
    }
}

