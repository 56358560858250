.tracking-cont{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
    gap: 40px;
}
.tracking-cont .preview{
    width: 100%;
    max-width: 300px;
    display: flex;
    gap: 30px;
    flex-direction: column;
    
    align-items: center;
    justify-content: center;
}
.tracking-cont .preview .img{
    width: 210px;
    background-color: rgba(60, 60, 60, 0.05);
    height: 290px;
    position: relative;
    border-radius: var(--br-1);
overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tracking-cont .butt{
    display: flex;
    position: relative;
    overflow: hidden;
    padding: 10px 24px;
    border-radius: 19px;
    background: #ffffff57;
    backdrop-filter: blur(1px);
    justify-content: center;
    align-items: center;
    gap: 20px;   
    color: var(--Isabeline, #F7F5F2);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'HK Grotesk-Medium';
    font-weight: 500;
    font-size: 12px;
    font-style: normal;
    line-height: normal; 
}
.tracking-cont .status-cont{
    display: flex;
    height: fit-content;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    max-width: 560px;
}
.tracking-cont .status-cont .status{
    padding: 16px 13px;
    display: flex;
    background: #fafafa;
    opacity: 0.7;
    gap:12px;
    border: 1px solid rgba(51, 51, 50, 0.20);
    align-items: center;
    width: calc(100% - 40px);
    color: var(--jet-black-4);
    margin: auto;
}
.tracking-cont .status-cont .status:first-child{
    border-radius:  10px 10px 0px 0px;
}
.tracking-cont .status-cont .status:last-child{
    border-radius: 0px 0px 10px 10px
}
.tracking-cont .status-cont .active {
    width: calc(100% - 40px);
    color:  var(--jet-black);
    background-color: #fafafa;
    opacity: 1;
}
.tracking-cont .status-cont .active2 {
    width: calc(100% - 0px);
    background-color:  var(--jet-black);
    color: var(--isabeline);
    border-radius: 10px !important;
    opacity: 1;
}
.tracking-cont .status-cont .active2 .tick{
    filter: brightness(10);
}
.tracking-cont .status-cont .icon{
    width: 40px;
    height: 40px;
    opacity: 0.5;
    overflow: visible;
    background-color: var(--isabeline);

    padding: 10px;
    border-radius: 50px;
}
.tracking-cont .status-cont .active .icon{
    background-color:#F7F5F2;
    opacity: 1;
}
.tracking-cont .status-cont .text-wrapper{
    display: flex;
    flex-direction: column;
    font-family: 'HK Grotesk-RegularLegacy';
    font-weight: 400;
    font-size: 10px;
    font-style: normal;
    line-height: 140.02%; /* 14.002px */
}
.tracking-cont .status-cont .text-wrapper .sub-heading{
    font-weight: 400;   
}
.tracking-cont .status-cont .tick{
    margin-left: auto;
}
.second-cont {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 40px;
    position: relative;
    min-width: 210px;
}

.second-cont .wrap {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
}

.second-cont .text-wrapper {
    color: #333332;
    font-family: "HK Grotesk-SemiBoldLegacy", Helvetica;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15.8px;
    position: relative;
    width: 44px;
}

.second-cont .ammounr {
    align-items: center;
    background-color: #3333321a;
    border-radius: 56px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    overflow: hidden;
    padding: 8px 31px;
    position: relative;
}

.second-cont .div {
    color: var(--jet-black);
    font-family: "HK Grotesk-SemiBoldLegacy", Helvetica;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -2px;
    position: relative;
    width: fit-content;
}

.second-cont .button-mobile {
    align-items: center;
    background-color: var(--jet-black);
    display: flex;
    flex: 0 0 auto;
    gap: 20px;
    justify-content: center;
    padding: 12px 24px;
    position: relative;
    width: 210px;
}

.second-cont .text-wrapper-2 {
    color: var(--isabeline);
    font-family: "HK Grotesk-SemiBoldLegacy", Helvetica;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}
@media screen and (min-width:768px) {
    .tracking-cont .status-cont .text-wrapper{
        font-size: 14px;
    }
    .tracking-cont .preview .img{
        height: 352px;
        width: 248px;
    }


}