.flipbook-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 40px 40px;
    justify-content: center;
    padding: 0px 40px;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: center;
    transition: 0.7s;
    flex-direction: row-reverse;
}
.absolute{
    position: absolute;
    z-index: 999;
    top: 0;
    scale: 1.01;
}
.flipbook-container .flipbook {
    height: 664px;
    object-fit: cover;
    position: relative;
    width: 469px;
    
}


.flipbook-container .flipbook-content-container .cont {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 25px;
    min-width: 500px;
    position: relative;
    max-width: fit-content;
    position: relative;
    margin: 0;
}

.flipbook-container .flipbook-content-container .text-wrap {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto; 
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    position: relative;
    width: 100%;
}

.flipbook-container .flipbook-content-container .heading {
    align-self: stretch;
    color: var(--newjet-black);
    font-family:  'freight-big-pro', Helvetica;
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
}

.flipbook-container .flipbook-content-container .tag {
    color: var(--newjet-black);
    font-family: "HK Grotesk-RegularLegacy", Helvetica;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    opacity: 0.5;
    position: relative;
    width: 100%;
}

 .hidebutoon{
    display: none;
}
.iframe-container{
    position: relative;
}
.iframe-container .iframe-protector{
    position: absolute;
    z-index: 20;
    background-size:105%;
    height: 100%;
    width: 100%;
}

@media screen and (max-width: 768px) {

    .flipbook-container .flipbook {
        width: 210px;
        height: auto;
        aspect-ratio: 469/664;
    }
    .flipbook-container .flipbook-content-container  .heading {
        color: var(--new-jet-black, #333332);
        text-align: center;
        font-family: freight-big-pro;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 107%;
    }
    .absolute{
        position: absolute;
        z-index: 999;
        
        scale: 1;
    }
    .flipbook-container .flipbook-content-container .tag{
        color: var(--new-jet-black, #333332);
        text-align: center;
        font-family: "HK Grotesk-RegularLegacy", Helvetica;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100%;
        text-align: center;
    }
    .flipbook-container .flipbook-content-container .cont {
        min-width: 130px;
        justify-content: center;
    }
    .flipbook-container .flipbook-content-container .text-wrap {
      
        gap: 6px !important;
      
    }
    .flipbook-container .hidebutoon{
        display: block;
    }

}