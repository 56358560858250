.faq-body{
    position: fixed;
    height: 100%;
    width: 100%;
    background: var(--isabeline);
    z-index: 12;
    top: 0;
    overflow: auto;
    padding: 20px;
    padding-top: 110px;
    padding-bottom: 100px;
    margin-top: 0;
    transition: 0.2s;
    gap: 50px;
}

.note{
    position: relative;
    width: 100%;
    max-width: 770px;
    border-radius: var(--br-1);
    border: 1px solid #000;
    display: flex;
    color: var(--jet-black);
    flex-direction: row;
}
.note .logo{
    display: flex;
    width: 100px;
    background: #000;
    color: var(--isabeline);
    justify-content: center;
    align-items: center;
    font-family: 'Raleway', serif;
    font-size: 24px;
    font-weight: 200;
    letter-spacing: -3px;
    line-height: normal;
    margin-left: -3px;
    position: relative;
    white-space: nowrap;
    border-radius: var(--br-1) 0px 0px var(--br-1);
    
}
.note .disc{
    color: var(--jet-black);
    padding: 10px;
}
.note .disc a{ 
    color: var(--jet-black-2);
    text-decoration: underline !important;

}

.faq-body .heading{ 
    font-size: 24px;
    font-family: 'HK Grotesk-Medium';
}

.faq-body .faq-cont{
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: -10px;

}

.faq-body .set{
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-family: 'HK Grotesk-Medium';
    transition: 0.2s;
    gap: 15px;
}

.faq-body .active2{
    background-color: #F8F8F8;
    padding: 30px;
    border-radius: var(--br-1);
}


.faq-body .set .question-expand{
    display: flex;
    justify-content: space-between;
    gap: 25px;
}

.faq-body .set .question-expand .question{
    display: flex;
align-items: center;

cursor: pointer;
}

.faq-body .set .question-expand .expand{
    font-size: 28px;
    font-family: 'HK Grotesk-Medium';
    cursor: pointer;
    height: fit-content;
}

.faq-body .set .question-expand .expand svg{
    height: 18px;
    width: 18px;
}

.faq-body .set .answer{
    height: 0px;
    overflow: hidden;
    color: var(--jet-black-3);
    width: 90%;
    transition: 0.2s;

}

.faq-body .set .active{
    height: auto;
    overflow: hidden;
}

.faq-cont-small .active2{
    margin-bottom: 10px;
}

@media screen and (max-width:768px) {
    .faq-body{
        position: fixed;
        height: 100%;
        width: 100%;
        background: var(--isabeline);
        top: 0;
        padding-top: 80px;
        gap: 30px;
    
    }
    .faq-body .faq-cont{
        display: flex;
        flex-direction: column;
        gap: 15px;
    
    }
    
    .note{
        position: relative;
        width: 100%;
        max-width: 770px;
        border-radius: var(--br-1);
        display: flex;
        flex-direction: row;
    }
    .note .logo{
        display: flex;
        width: 100px;
        color: var(--isabeline);
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 200;
        letter-spacing: -1.5px;
        
    }
    .note .disc{
        padding: 10px;
        font-size: 12px;
        font-family: 'HK Grotesk-Regular';
    }
    .note .disc a{ 
        color: var(--jet-black-2);
        text-decoration: underline !important;
    }
    .faq-body .heading{ 
        font-size: 16px;
        font-family: 'HK Grotesk-Medium';
    }


    
.faq-body .set{
    display: flex;
    flex-direction: column;
    font-size: 13px;
    font-family: 'HK Grotesk-Medium';
    transition: 0.2s;
    gap: 10px;
}

.faq-body .active2{
    background-color: #F8F8F8;
    padding: 20px;
    border-radius: var(--br-1);
}


.faq-body .set .question-expand{
    display: flex;
    justify-content: space-between;
    gap: 25px;
}


.faq-body .set .question-expand .expand svg{
    height: 14px;
    width: 14px;
}

.faq-body .set .question-expand .question{
    display: flex;
align-items: center;

cursor: pointer;
}

.faq-body .set .question-expand .expand{
    font-size: 22px;
    font-family: 'HK Grotesk-Medium';
    cursor: pointer;
}

.faq-body .set .answer{
    height: 0px;
    overflow: hidden;
    color: var(--jet-black-3);
    width: 90%;
    transition: 0.2s;

}

.faq-body .set .active{
    height: auto;
    overflow: hidden;
}

.faq-cont-small .active2{
    margin-bottom: 6px;
}
    
}