.abstract{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-top: 40px;
}

.body .abstract{
    padding-left: 20px;
    padding-right: 20px;
}

.abstract .top{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.abstract .top .heading{
    width: 100%;
    text-align: center;
    color: var(--Jet-Black, #333332);
    font-family: 'HK Grotesk-Medium';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.abstract .top .sub-heading{
    width: 80%;
    margin: auto;
    opacity: 0.6;
    color: var(--Jet-Black, #333332);
    font-family: 'HK Grotesk-Regular';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    text-align: center;
}
.abstract .category{
    color: var(--Jet-Black, #333332);
    font-family: freight-big-pro, Helvetica;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: -20px;
    margin-bottom: -20px;
    text-align: center;
}

.abstract .paging{
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    position: relative;
}

.abstract .hr{
    position: absolute;
    width: 100%;
    height: 2px;
    background-color:  var(--jet-black) ;
    z-index: 0;
}

.abstract .paging .page{
    border-radius: 100px;
    background: var(--isabeline);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: var(--isabeline);
    height: 27px;
    width: 27px;
    color: var(--jet-black);
    border: 1px solid var(--jet-black);
    text-align: center;
    font-family: 'HK Grotesk-SemiBold';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    z-index: 1;
    line-height: normal;
}

.abstract .paging .Pagefill{
    background-color: var(--jet-black);
    color: #fff;
}
.abstract .form{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap:28px;
    background: #fff;
    border-radius: 40px ;
border: 1px solid var(--jet-black-7);
    padding: 40px 17px;
}


.abstract .form .input-set{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 10px;
    padding: 15px;
}
.abstract .form .input-set .question{
    color: var(--Jet-Black, #333332);
    font-family: 'HK Grotesk-Medium';
    font-size: 14px;
    font-weight: 500;
    line-height: 130%; /* 18.2px */
}
.abstract .form .input-set .inputs{
    display: flex;
    width: 100%;
    gap: 8px;
    align-items: center;
    justify-content: center;
    outline: none;
    border-radius: 0;
    outline: none;
}
.abstract .form .input-set .inputs .answer{
    display: flex;
    width: 100%;
    padding: 5px;
    border: none;
    border-bottom: 0.5px solid var(--Jet-Black, #333332);
    align-items: center;
    gap: 16px;
    background-color: transparent;
    color: var(--Jet-Black, #333332);
    font-family: 'HK Grotesk-Regular';
    color: var(--Jet-Black, #333332);;
font-size: 12px;
font-style: normal;
font-weight: 400;
    line-height: 130%;
    border-radius: 0;
    outline: none;
}
.abstract .form .input-set audio{
    width: 100%;
}
@supports (-webkit-appearance:none) {
    .abstract .form .input-set audio {
      /* Your styles for Safari */
      width: 100%;
    }
  }

  @media screen and (-webkit-min-device-pixel-ratio:0) {
    .abstract .form .input-set audio {
      /* Your styles for Android */
      width: 100%;
    }
  }
  
audio::-webkit-media-controls-panel {
    background-color: #e6e6e6;
  }
@keyframes recording {
    25% {opacity: 1;}
    75% {opacity: 0.6;}
    100% {opacity: 1;}
  }
.abstract .form .input-set .inputs .buton{
    height: 30px;
    width: 30px;
    background-color: #333332;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    cursor: pointer;
}

.abstract .images-display{
    display: flex;
    gap: 10px;
    width: 100%;
    overflow-x: auto;
    margin: auto;
    margin-top: 20px;
}
.abstract .images-display div{
    height: auto;
    width: auto;
    position: relative;
    flex-shrink: 0;
}
.abstract .images-display .button-d{
    position: absolute;
    right: 5px;
    top: 5px;
    height: 28px;
    width: 28px;
    max-height: 28px;
    max-width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #33333283;
    border: none;
    border-radius: 100px;
    cursor: pointer;
}
.abstract .images-display img{
    height: 107px;
    width: auto;
    
}

.abstract .uplode-images{
    color: var(--Jet-Black, #333332);
    text-align: center;
    font-family: 'HK Grotesk-Regular';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
}
.abstract .next-button{
    display: flex;
    overflow: hidden;
    padding: 10px 20px;
    border-radius: 68px;
    background: var(--jet-black);
    justify-content: center;
    align-items: center;
    gap: 6px;
    color: var(--isabeline, #F7F5F2);
    font-family: 'HK Grotesk-Medium';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    width: fit-content;
    text-align: center;
}
.abstract .next-cont{
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 0;
    background-color: var(--isabeline);
    padding: 10px 0px;
}
.dashboard-body .abstract .next-cont{
    bottom: -20px;
    padding: 10px 0px;
}
.abstract .progress-cont{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.abstract .progress-cont .headin{
    font-family: 'HK Grotesk-Medium';
    font-size: 16px;
    font-weight: 500;
}

.abstract .progress-cont .status{
    font-family: 'HK Grotesk-Regular';
    color: var(--Jet-Black, #333332);;
font-size: 14px;
font-style: normal;
font-weight: 400;
}
.start-window {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 50px;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: var(--isabeline);
  z-index: 20;
  padding-left: 20px;
  top: 0px;
  left: 0;
  transition: 0.3s;
  padding-top: 100px;
  padding-bottom: 80px;

}


.start-window .headrer-text {
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.start-window  .welcome-imurs {
  font-size: 24px;
  font-family: 'Raleway';
  letter-spacing: -3px;
  font-weight: 300;
}

.start-window .text-wrapper {
  color: var(--jet-black);
  font-family: "HK Grotesk-LightLegacy", Helvetica;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.start-window .partner-name {
  color: var(--jet-black);
  font-family: "HK Grotesk-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.start-window .parent {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1px;
  margin-right: -106.22px;
  position: relative;
}

.start-window .cont {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.start-window .div {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.start-window .headin {
  color: var(--jet-black);
  font-family: 'HK Grotesk-SemiBold', Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  margin-top: -1px;
  position: relative;
  width: 184px;
}

.start-window .text {
  color: var(--jet-black);
  font-family: "HK Grotesk-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  opacity: 0.6;
  position: relative;
  width: 184px;
}

.start-window .diversity {
  height: 36px;
  position: relative;
  width: 36px;
}

.start-window .cat {
  color: var(--jet-black);
  font-family: "HK Grotesk-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  text-transform: none;
}


.start-window .binder {
  height: 359.26px;
  margin-bottom: -6px;
  margin-top: -6px;
  position: relative;
  width: auto;
}
.start-window .next-button{
    display: flex;
    overflow: hidden;
    padding: 10px 20px;
    border-radius: 68px;
    background: var(--jet-black);
    justify-content: center;
    align-items: center;
    gap: 6px;
    color: var(--isabeline, #F7F5F2);
    font-family: 'HK Grotesk-Medium';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    width: fit-content;
    text-align: center;
    cursor: pointer;
}
@media screen and (min-width:769px) {
    .abstract .category{
        font-size: 24px;   
    }
    .abstract .paging{
        width: 500px;
    }
    .abstract .paging .page{
        height: 44px;
        width: 44px;
        font-size: 20px;
    }
    .abstract .form{
        border-radius: 24px;
        border: 1px solid var(--jet-black-3);
        padding: 100px 80px;
        max-width: 950px;
        width: 90%;
    }
    .abstract .form .input-set{
        gap: 20px;
    }
    .abstract .form .input-set .question{
        font-size: 20px;
        
    }
    .abstract .form .input-set .inputs{
        gap: 20px;
    }
    .abstract .form .input-set .inputs .answer{
        font-size: 18px;
        padding: 28px 40px 15px 0px;
        
    }
    .abstract .form .input-set .inputs .buton{
        height: 40px;
        width: 40px;
        
    }
    .abstract .images-display img{
        height: 170px;
    }

    .abstract .next-button{
        padding: 20px 80px;
        border-radius: 68px;
        font-size: 16px;
        
    }
    .abstract .next-cont{
       position: relative;
       max-width: 950px;
       width: 100%;
       margin: auto;
    }
    .start-window {
        padding-left: 40px;
        padding-top: 60px;
        padding-bottom: 60px;
        gap:70px;
        background-color: var(--isabeline);
    }
    .start-window .welcome-imurs {
        font-size: 48px;
        letter-spacing: -5px;

    }
    .start-window .text-wrapper {
        font-size: 36px;
    }
    .start-window .headrer-text .partner-name {
        font-size: 36px;
    }
    .start-window .headin {
        font-size: 32px;
        width: 80%;
    }
    .start-window .text {
        width: 80%;
        font-size: 20px;
    }
    .start-window .diversity {
        height: 70px;
        position: relative;
        width: 70px;
      }
      .start-window .cat {
        font-size: 24px;

      }
      .start-window .binder {
        height: 604.574px;
      }
      .start-window .next-button{
        width: 285px;
        padding: 15px 30px;
        justify-content: center;
        font-size: 16px;
        align-items: center;
        gap: 9px;
      }

}