
.ordercheck2
{
    color: var(--jet-black-2);
font-family: "HK Grotesk-RegularLegacy", Helvetica;
font-size: 14px;
font-weight: 400;

letter-spacing: 0;
line-height: normal;

text-align: center;

width: 360px; 

}

