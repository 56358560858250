

.order-cardV2 {
    align-items: center;
    background-color: #f3f3f3;
    display: inline-flex;
    gap: 26px;
    padding: 29px;
    position: relative;
    min-width: 550px;
    border-radius: var(--br-1);
}

.order-cardV2 .image {
    background-color: #ffffff;
    height: 191px;
    position: relative;
    aspect-ratio: 2/3;
    filter: brightness(0.95);
    border-radius: var(--br-1);

}

.order-cardV2 .cont {
    align-items: start;
    display: inline-flex;
    width: 100%;
    flex-direction: column;
    gap: 20.68px;
    justify-content: flex-end;
    position: relative;
}

.order-cardV2 .text-wrap {
    align-items: flex-start;
    display: flex;
    width: 100%;
    
    flex-direction: column;
    gap: 8px;
    position: relative;
}

.order-cardV2 .main-cont {
    align-items: center;
    display: inline-flex;
    
    flex-wrap: wrap;
    gap: 11px;
    justify-content: center;
    position: relative;
}


.order-cardV2 .overlap-group {
    background-size: 100% 100%;
    height: 46px;
    left: 2px;
    position: relative;
    width: 46px;
    background-size: 100% auto;
    background-position: center;
    background-image: url('../../../public/logos/planSymbol/idyllic.svg');
}

.order-cardV2 .name {
    color: var(--jet-black);
    font-family: "HK Grotesk-SemiBold", Helvetica;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
    margin-top: -0.85px;
    position: relative;
    width: fit-content;
    
}

.order-cardV2 .text-wrapper {
    font-weight: 700;
    overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: 330px;
}

.order-cardV2 .span {
    font-family: "HK Grotesk-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.9px;
    color: var(--jet-black-4);
}

.order-cardV2 .flex {
    align-items: center;
    display: flex;
    width: 100%;
    gap: 20px;
    justify-content: space-between;
    position: relative;
}

.order-cardV2 .div {
    align-items: center;
    display: flex;
    gap: 5px;
    justify-content: center;
    position: relative;
}

.order-cardV2 .text {
    color: var(--jet-black-2);
    font-family: "HK Grotesk-RegularLegacy", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    opacity: 0.6;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.order-cardV2 .status-cont {
    align-items: center;
    border-color: #000000;
    border-left-style: solid;
    border-left-width: 0.25px;
    display: flex;
    gap: 3px;
    padding: 0px 0px 0px 7.5px;
    position: relative;
}

.order-cardV2 .icon {
    height: 14px;
    position: relative;
    width: 14px;
}

.order-cardV2 .status {
    color: var(--jet-black);
    font-family: "HK Grotesk-SemiBoldLegacy", Helvetica;
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0;
    line-height: normal;
    margin-right: -2px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.order-cardV2 .share {
    padding: 8px;
    background-color: var(--jet-black-7);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--br-max);
}

.order-cardV2 .img {
    height: 24px;
    position: relative;
    width: 24px;
}

.order-cardV2 .button {
    align-items: center;
    align-self: stretch;
    background-color: var(--jet-black);
    display: flex;
padding: 14.706px 29.412px;
justify-content: center;
align-items: center;
gap: 25.147px;
align-self: stretch;
border-radius: var(--br-max);

}

.order-cardV2 .text-2 {
    color: var(--isabeline);
    font-family: "HK Grotesk-Medium", Helvetica;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.85px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.create-order-fix{
    display: flex;
    position: fixed;
    background-color: var(--jet-black);
    bottom: 30px;
    right: 30px;
    height: 50px;
    z-index: 15;
    width: 50px;
    align-items: center;
    justify-content: center;
    border-radius: 123px;
    color: var(--isabeline);
    font-family: "HK Grotesk-Regular";
    font-size: 18px;
    transition: 0.1s;
}

@media screen and (max-width:768px) {
    
    .create-order-fix{
        display: flex;
        position: fixed;
        background-color: var(--jet-black);
        bottom: 80px;
        right: 20px;
        height: 50px;
        z-index: 15;
        width: 50px;
        align-items: center;
        justify-content: center;
        border-radius: 123px;
        color: var(--isabeline);
        font-family: "HK Grotesk-Regular";
        font-size: 18px;
        transition: 0.1s;
    }

.order-cardV2 {
    align-items: start;
    background-color: #efefef;
    display: flex;
    gap: 15px;
    justify-content: start;
    min-height: auto;
    min-width: 350px;
    padding: 20px;
    position: relative;
}

.order-cardV2 .image {
    background-color: #ffffff;
    height: 107px;
    position: relative;
}

.order-cardV2 .cont {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 13.68px;
    justify-content: flex-end;
    position: relative;
    width: 100%;
}

.order-cardV2 .text-wrap {
    align-items: flex-start;
    display: flex;
    
    flex-direction: column;
    gap: 5px;
    position: relative;
    width: 100%;
}

.order-cardV2 .main-cont {
    align-items: center;
    display: inline-flex;
    
    flex-wrap: wrap;
    gap: 8px 8px;
    justify-content: center;
    position: relative;
}

.order-cardV2 .group {
    height: 31.5px;
    position: relative;
    width: 31.5px;
}

.order-cardV2 .overlap-group {
    background-size: 100% 100%;
    height: 32px;
    left: 2px;
    position: relative;
    width: 27px;
    background-image: url('../../../public/logos/planSymbol/idyllic.svg');
}

.order-cardV2 .name {
    color: var(--jet-black);
    font-family: "HK Grotesk-Bold", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 14px;
    margin-top: -0.85px;
    position: relative;
    width: fit-content;
}

.order-cardV2 .text-wrapper {
    font-weight: 700;
  width: 180px;

}

.order-cardV2 .span {
    font-family: "HK Grotesk-Medium", Helvetica;
    font-size: 10px;
    font-weight: 500;
    line-height: 16.9px;
}

.order-cardV2 .flex {
    align-items: center;
    display: flex;
    
    gap: 20px;
    justify-content: space-between;
    position: relative;
    width: 100%;
}

.order-cardV2 .div {
    align-items: center;
    display: flex;
    gap: 5px;
    justify-content: start;
    position: relative;
    width: 126px;
}

.order-cardV2 .text {
    color: var(--jet-black);
    font-family: "HK Grotesk-RegularLegacy", Helvetica;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    opacity: 0.6;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.order-cardV2 .status-cont {
    align-items: center;
    border-color: #000000;
    border-left-style: solid;
    border-left-width: 0.25px;
    display: flex;
    gap: 3px;
    padding: 0px 0px 0px 5px;
    position: relative;
    width: 44px;
}

.order-cardV2 .icon {
    height: 10px;
    position: relative;
    width: 10px;
}

.order-cardV2 .status {
    color: var(--jet-black);
    font-size: 8px;
    font-family: 'HK Grotesk-Medium';
    font-weight: 600;
    line-height: normal;
    margin-right: -2px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.order-cardV2 .share {
    padding: 8px;
    background-color: var(--jet-black-7);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--br-max);
}

.order-cardV2 .img {
    height: 10px;
    position: relative;
    width: 10px;
}

.order-cardV2 .button {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 17.1px;
    justify-content: center;
    padding: 12px 20px;
    position: relative;
    width: 100%;
}

.order-cardV2 .text-2 {
    color: var(--isabeline);
    font-family: "HK Grotesk-Medium", Helvetica;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.85px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}
}
