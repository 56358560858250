.IMURS-corporate {
    background-color: #f7f5f2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.cardsspacing
{
    display: flex;
    flex-direction: column;
    gap: 40px;
    
}
.IMURS-corporate .div-2 {
    background-color: var(--newisabeline);
    height: 1500px;
    position: relative;
    width: 1440px;
}

.IMURS-corporate .text-wrapper-3 {
    color: var(--newjet-black);
    font-family: freight-big-pro
, Helvetica;
    font-size: 42px;
    font-weight: 400;
    left: 651px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 64px;
    white-space: nowrap;
}

.IMURS-corporate .button-secondary-instance {
    left: 1202px !important;
    position: absolute !important;
    top: 50px !important;
}

.IMURS-corporate .menu {
    height: 42px;
    left: 60px;
    position: absolute;
    top: 58px;
    width: 42px;
}

.IMURS-corporate .frame-6 {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 30px;
    left: 388px;
    position: absolute;
    top: 209px;
}

.IMURS-corporate .text-wrapper-4 {
    color: var(--newjet-black);
    font-family: freight-big-pro
, Helvetica;
    font-size: 96px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.IMURS-corporate .frame-7 {
    align-items: center;
    border: 1px solid;
    border-color: var(--jet-black);
    display: flex;
    flex: 0 0 auto;
    gap: 92px;
    padding: 20px 40px;
    position: relative;
    width: 451px;
}

.IMURS-corporate .text-wrapper-5 {
    color: var(--jet-black);
    font-family: "HK Grotesk-MediumLegacy", Helvetica;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.5px;
    opacity: 0.6;
    position: relative;
    width: fit-content;
}

.IMURS-corporate .img {
    height: 30px;
    position: relative;
    width: 30px;
}

.IMURS-corporate .text-wrapper-6 {
    color: var(--persian-red);
    font-family: "HK Grotesk-MediumLegacy", Helvetica;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.IMURS-corporate .group {
    height: 778px;
    left: 60px;
    position: absolute;
    top: 531px;
    width: 1320px;
}

.IMURS-corporate .group-2 {
    background-color: var(--jet-black);
    height: 684px;
    left: 0;
    position: absolute;
    top: 94px;
    width: 421px;
}

.IMURS-corporate .div-wrapper {
    align-items: center;
    background-color: #f7f5f2;
    display: inline-flex;
    gap: 10px;
    justify-content: center;
    left: 0;
    padding: 16px 32px;
    position: absolute;
    top: 30px;
}

.IMURS-corporate .text-wrapper-7 {
    color: #333332;
    font-family: freight-big-pro
, Helvetica;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.IMURS-corporate .frame-8 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 151px;
    left: 32px;
    position: absolute;
    top: 110px;
}

.IMURS-corporate .frame-9 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 34px;
    position: relative;
}

.IMURS-corporate .text-wrapper-8 {
    color: var(--isabeline);
    font-family: "HK Grotesk-LightLegacy", Helvetica;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    opacity: 0.5;
    position: relative;
    width: 341px;
}

.IMURS-corporate .frame-10 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    position: relative;
}

.IMURS-corporate .each-magazine {
    color: var(--isabeline);
    font-family: "HK Grotesk-SemiBoldLegacy", Helvetica;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.IMURS-corporate .line {
    height: 1px;
    object-fit: cover;
    position: relative;
    width: 357px;
}

.IMURS-corporate .frame-11 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
}

.IMURS-corporate .frame-12 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
}

.IMURS-corporate .text-wrapper-9 {
    color: var(--isabeline);
    font-family: "HK Grotesk-RegularLegacy", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    opacity: 0.8;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.IMURS-corporate .button-secondary-2 {
    color: var(--isabeline) !important;
}

.IMURS-corporate .button-secondary-3 {
    border-color: var(--isabeline) !important;
    flex: 0 0 auto !important;
    width: 357px !important;
}

.IMURS-corporate .star-wrapper {
    background-image: url(./star-6.svg);
    background-size: 100% 100%;
    height: 60px;
    left: 331px;
    position: absolute;
    top: 30px;
    width: 60px;
}

.IMURS-corporate .star-2 {
    height: 23px;
    left: 18px;
    position: absolute;
    top: 18px;
    width: 23px;
}

.IMURS-corporate .group-3 {
    background-color: var(--jet-black);
    height: 684px;
    left: 900px;
    position: absolute;
    top: 94px;
    width: 420px;
}

.IMURS-corporate .frame-13 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 111px;
    left: 32px;
    position: absolute;
    top: 110px;
}

.IMURS-corporate .line-2 {
    height: 1px;
    object-fit: cover;
    position: relative;
    width: 356px;
}

.IMURS-corporate .button-secondary-4 {
    border-color: var(--isabeline) !important;
    flex: 0 0 auto !important;
    width: 356px !important;
}

.IMURS-corporate .overlap-group-wrapper {
    height: 60px;
    left: 330px;
    position: absolute;
    top: 30px;
    width: 60px;
}

.IMURS-corporate .overlap-group {
    background-image: url(./star-7.svg);
    background-size: 100% 100%;
    height: 60px;
    left: 4px;
    position: relative;
    width: 52px;
}

.IMURS-corporate .star-3 {
    height: 23px;
    left: 16px;
    position: absolute;
    top: 18px;
    width: 20px;
}

.IMURS-corporate .group-4 {
    background-color: var(--persian-red);
    height: 684px;
    left: 450px;
    position: absolute;
    top: 0;
    width: 421px;
}

.IMURS-corporate .text-wrapper-10 {
    color: #b73e33;
    font-family: freight-big-pro
, Helvetica;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.IMURS-corporate .frame-14 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 71px;
    left: 32px;
    position: absolute;
    top: 110px;
}

.IMURS-corporate .img-wrapper {
    background-image: url(./star-9.svg);
    background-size: 100% 100%;
    height: 60px;
    left: 331px;
    position: absolute;
    top: 30px;
    width: 60px;
}

.IMURS-corporate .socials-2 {
    left: 0 !important;
    position: absolute !important;
    top: 1409px !important;
}

.negative-space{
    gap: 120px;
}

@media screen and (max-width: 768px)
{

    .negative-space{
        gap: 80px;
    }
    .negative-space .cardsspacing{
        gap: 25px;
    }

    .cardsspacing
    {

        gap:20px;
    }
}