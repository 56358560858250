.dashboard-body .dash-footer{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 20px;
    gap:10px;
    margin-top: auto;
    border-top: 0.5px solid rgba(51, 51, 50, 0.40);
}

.dashboard-body .dash-footer .link-cont{
    display: flex;
    gap: 30px;
    width: fit-content;
}

.dashboard-body .dash-footer .link-cont .link{
    opacity: 0.5;
    color: var(--Jet-Black, #333332);
    font-family: "HK Grotesk-RegularLegacy";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.dashboard-body .dash-footer .icon-cont{
    display: flex;
    gap: 18px;
    width: fit-content;
}
@media screen and (max-width:768px) {
    .dashboard-body .dash-footer{
        flex-direction: column;
        align-items: center;
        opacity: 0.7;
    }
    .dashboard-body .dash-footer .link-cont{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px 15px;
        width: fit-content;
        flex-wrap: wrap;
    }
    .dashboard-body .dash-footer .icon-cont{
        display: flex;
        gap: 18px;
        width: fit-content;
    }
    .dashboard-body .dash-footer .link-cont .link{
        font-size: 10px;
    }
    .dashboard-body .dash-footer .icon-cont img{
        width: 14.25px;
        height: auto;
    }
}